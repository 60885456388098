export function encodeId(id: string): string {
    if (id === "0" || id === "") {
        return "";
    }

    const divisor: bigint = BigInt(32);
    const base32: string = "w53v4g9em2la6frjtznch78ybuidspkq";
    let num: bigint = BigInt(id);
    let arr: string[] = [];

    while (num > BigInt(0)) {
        arr.push(base32[Number(num % divisor)]);
        num = num / divisor;
    }

    return arr.join('');
}