import {WindowParam} from "../../../library/context/WindowContext";
import {ColumnUnit} from "../../../library/atomic/unit/ColumnUnit";
import {SizeAttr} from "../../../library/basic/size";
import {mediumRadius, smallPadding} from "../display/layout";
import {BoxAttr, ColumnAttr} from "../../../library/basic/compose";
import {TcCvTemplatesData} from "../data/TcCvTemplates";
import {TcCvCharactersData} from "../data/TcCvCharacters";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import {SpanText} from "../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../library/basic/font";
import {ColorAttr} from "../../../library/basic/color";
import discordJoinPadAndPhone from "../../../assets/prompt/discord_join_pad_and_phone.svg"
import detailCopyIcon from "../../../assets/prompt/detail_copy_icon.svg"
import {ImageUnit} from "../../../library/atomic/image/ImageUnit";
import {tosLink} from "../../../library/util/tosUtil";
import {BoxUnit} from "../../../library/atomic/unit/BoxUnit";
import {GLBPlayer} from "./GlbPlayer";
import {VideoSwitcher} from "./VideoSwitcher";
import {TcCvMixVideosData} from "../data/TcCvMixVideos";

export function DetailPanelPadAndPhone(wp: WindowParam, name: string, tags: string[],
                                       cardInnerElement: (cardWidthNum: number, cardHeightNum: number) => JSX.Element,
                                       copyIconClick: () => void,
                                       bottomButtonImg: string,
                                       bottomButtonClick: () => void) {
    let widthNum = 418 < wp.currWidth - 44 ? 418 : wp.currWidth - 44
    let heightNum = widthNum / 347 * 623

    let width = widthNum + "px"
    let height = heightNum + "px"

    // 卡片相关
    let cardHeightNum = widthNum / 347 * 456

    // discord按钮相关
    let discordButtonTopMarginNum = 8
    let discordButtonBottomMarginNum = widthNum / 347 * 34.32
    let discordButtonWidthNum = widthNum / 347 * 314
    let discordButtonHeightNum = widthNum / 347 * 37.68
    let discordTotalHeight = discordButtonTopMarginNum + discordButtonHeightNum + discordButtonBottomMarginNum

    // 文字部分剩余高度
    let textInfoHeight = heightNum - cardHeightNum - 2 - discordTotalHeight

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
            }}
            style={{
                "margin": "auto",
                "borderRadius": mediumRadius,
                "background": "#121212",
                "width": width,
                "height": height
            }}>
            <div
                style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "display": "flex",
                    "justifyContent": "center",
                    "width": widthNum,
                    "height": cardHeightNum + "px"
                })}
            >
                {cardInnerElement(widthNum, (widthNum / 347 * 456))}
            </div>

            <BoxUnit
                colorAttr={new ColorAttr(wp, "", "#232326")}
                sizeAttr={new SizeAttr(wp, width, "2px")}/>

            <BoxUnit
                customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, (widthNum - 32) + "px", textInfoHeight + "px")}>
                <RowUnit
                    sizeAttr={new SizeAttr(wp, (widthNum - 32) + "px")}
                    customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                    <ColumnUnit>
                        <SpanText
                            customStyleAttr={{
                                "justifyContent": ""
                            }}
                            colorAttr={new ColorAttr(wp, "#ffffff")}
                            fontAttr={new FontAttr(wp, "18px", "600")}
                        >
                            {name}
                        </SpanText>
                        {
                            tags.length > 0 &&
                            <RowUnit customStyleAttr={{
                                "marginTop": smallPadding
                            }}>
                                {
                                    tags.slice(0, Math.min(3, tags.length)).map((t) => {
                                        return (
                                            <SpanText
                                                customStyleAttr={{
                                                    "justifyContent": "",
                                                    "marginRight": smallPadding
                                                }}
                                                colorAttr={new ColorAttr(wp, "#B5B8C5")}
                                                fontAttr={new FontAttr(wp, "16px", "600")}
                                            >
                                                {"#" + t}
                                            </SpanText>
                                        )
                                    })
                                }
                            </RowUnit>
                        }
                    </ColumnUnit>

                    {
                        bottomButtonImg == discordJoinPadAndPhone &&
                        <ImageUnit
                            onClick={() => {
                                copyIconClick()
                            }}
                            customStyleAttr={{"marginLeft": "auto"}}
                            sizeAttr={new SizeAttr(wp, "24px", "24px")}
                            url={detailCopyIcon}/>
                    }
                </RowUnit>
            </BoxUnit>

            <div
                onClick={() => {
                    bottomButtonClick()
                }}
                style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "marginTop": discordButtonTopMarginNum + "px",
                    "marginBottom": discordButtonBottomMarginNum + "px",
                    "width": discordButtonWidthNum + "px",
                    "height": discordButtonHeightNum + "px",
                })}
            >
                <img
                    style={{
                        "width": discordButtonWidthNum + "px",
                        "height": discordButtonHeightNum + "px",
                    }}
                    className={"hoverCursor"}
                    src={bottomButtonImg}
                    alt={"join discord"}/>
            </div>
        </div>
    )
}

function TcTemplateDetailPanelPadAndPhone(wp: WindowParam,
                                          tcCvTemplatesData: TcCvTemplatesData,
                                          copyIconClick: () => void, bottomButton: string,
                                          bottomButtonClick: () => void) {
    let tags = []
    try {
        tags = JSON.parse(tcCvTemplatesData.tags)
    } catch (e) {
        tags = []
    }

    return DetailPanelPadAndPhone(wp, "@ " + tcCvTemplatesData.name, tags,
        (w, h) => {
            if (tcCvTemplatesData.display_video == "") {
                return <div></div>
            }
            return <VideoSwitcher
                key={"VideoSwitcher-template-pad-and-phone-" + tcCvTemplatesData.id}
                wp={wp}
                w={w}
                h={h}
                switcherOffset={mediumRadius}
                mixVideo={tcCvTemplatesData.display_video}
                inpaintVideo={tcCvTemplatesData.inpaint_video}
                oriVideo={tcCvTemplatesData.org_video}
                switcherType={"template"}
            />
        }, copyIconClick, bottomButton, bottomButtonClick)
}

function TcMixVideoDetailPanelPadAndPhone(wp: WindowParam,
                                          tcCvMixVideosData: TcCvMixVideosData,
                                          copyIconClick: () => void, bottomButton: string,
                                          bottomButtonClick: () => void) {

    return DetailPanelPadAndPhone(wp, "@ " + tcCvMixVideosData.character.name, [],
        (w, h) => {
            if (tcCvMixVideosData.display_video == "") {
                return <div></div>
            }
            return <VideoSwitcher
                key={"VideoSwitcher-mix-video-pad-and-phone-" + tcCvMixVideosData.id}
                wp={wp}
                w={w}
                h={h}
                switcherOffset={mediumRadius}
                mixVideo={tcCvMixVideosData.display_video}
                inpaintVideo={tcCvMixVideosData.template.inpaint_video}
                oriVideo={tcCvMixVideosData.template.org_video}
                switcherType={"template"}
            />
        }, copyIconClick, bottomButton, bottomButtonClick)
}


function TcCharacterDetailPanelPadAndPhone(wp: WindowParam,
                                           tcCvCharactersData: TcCvCharactersData,
                                           copyIconClick: () => void,
                                           bottomButton: string,
                                           bottomButtonClick: () => void) {
    let tags = []
    try {
        tags = JSON.parse(tcCvCharactersData.tags)
    } catch (e) {
        tags = []
    }

    return DetailPanelPadAndPhone(wp, "@ " + tcCvCharactersData.name, tags,
        (w, h) => {
            if (tcCvCharactersData.rig_model == "") {
                return <div></div>
            }

            return <GLBPlayer
                key={"DetailPanelPadAndPhone-GLBPlayer-" + tcCvCharactersData.rig_model}
                src={tosLink(tcCvCharactersData.rig_model)}
                width={(w - 64) + "px"}
                height={(h) + "px"}
            />
        }, copyIconClick, bottomButton, bottomButtonClick)
}

export function TcTemplateDetailPanelPadAndPhoneBox(wp: WindowParam,
                                                    tcCvTemplatesData: TcCvTemplatesData,
                                                    copyIconClick: () => void, closeClick: () => void,
                                                    bottomButton: string = discordJoinPadAndPhone,
                                                    bottomButtonClick: () => void = () => {
                                                        window.open("https://discord.gg/aiuni", "_blank")
                                                    }) {
    return (
        <BoxUnit
            onClick={() => {
                closeClick()
            }}
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": "999",
                "background": "linear-gradient(to bottom,rgba(255,255,255,0.12),rgba(255,255,255,0.03))",
                "backdropFilter": "blur(20px)"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {TcTemplateDetailPanelPadAndPhone(wp, tcCvTemplatesData, copyIconClick, bottomButton, bottomButtonClick)}
        </BoxUnit>
    )
}

export function TcMixVideoDetailPanelPadAndPhoneBox(wp: WindowParam,
                                                    tcCvMixVideosData: TcCvMixVideosData,
                                                    copyIconClick: () => void, closeClick: () => void,
                                                    bottomButton: string = discordJoinPadAndPhone,
                                                    bottomButtonClick: () => void = () => {
                                                        window.open("https://discord.gg/aiuni", "_blank")
                                                    }) {
    return (
        <BoxUnit
            onClick={() => {
                closeClick()
            }}
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": "999",
                "background": "linear-gradient(to bottom,rgba(255,255,255,0.12),rgba(255,255,255,0.03))",
                "backdropFilter": "blur(20px)"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {TcMixVideoDetailPanelPadAndPhone(wp, tcCvMixVideosData, copyIconClick, bottomButton, bottomButtonClick)}
        </BoxUnit>
    )
}

export function TcCharacterDetailPanelPadAndPhoneBox(wp: WindowParam,
                                                     tcCvCharactersData: TcCvCharactersData,
                                                     copyIconClick: () => void, closeClick: () => void,
                                                     bottomButton: string = discordJoinPadAndPhone,
                                                     bottomButtonClick: () => void = () => {
                                                         window.open("https://discord.gg/aiuni", "_blank")
                                                     }) {
    return (
        <BoxUnit
            onClick={() => {
                closeClick()
            }}
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": "999",
                "background": "linear-gradient(to bottom,rgba(255,255,255,0.12),rgba(255,255,255,0.03))",
                "backdropFilter": "blur(20px)"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {TcCharacterDetailPanelPadAndPhone(wp, tcCvCharactersData, copyIconClick, bottomButton, bottomButtonClick)}
        </BoxUnit>
    )
}