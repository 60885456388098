import {getToken} from "../util/account";
import {TC_AIUNI_CV_BACKEND_URL} from "../../../library/util/apiUtil";
import {encodeId} from "../util/id";

export type V2MParam = {
    job_id: string,
    src_video: string,
    bucket: string
}

export type MixVideoParam = {
    template_id: string,
    character_id: string,
    template_cover: string,
    character_cover: string
}

export const JobTypeV2M = 1
export const JobTypeMixVideo = 2
export const JobTypeCharacter = 3

export const JobStatusInit = 0
export const JobStatusOK = 1
export const JobStatusPending = 2
export const JobStatusCancel = 3
export const JobStatusTimeout = 4
export const JobStatusError = 5

export type TcCvJobsData = {
    id: string
    uid: string
    typ: number
    param: string
    result: string
    status: number
    created_time: number
    updated_time: number
    v_2_m_param: V2MParam
    mix_video_param: MixVideoParam
}

export function emptyJobData(): TcCvJobsData {
    return {
        id: "0",
        uid: "",
        typ: 0,
        param: "",
        result: "",
        status: 0,
        created_time: 0,
        updated_time: 0,
        v_2_m_param: {
            job_id: "",
            src_video: "",
            bucket: ""
        },
        mix_video_param: {
            template_id: "",
            character_id: "",
            template_cover: "",
            character_cover: ""
        }
    }
}

export type TcCvJobsResp = {
    code: number
    msg: string
    data: TcCvJobsData
}

export function queryCvJobsById(id: string, needEncode: boolean, callback: (success: boolean, resp?: TcCvJobsResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryCvJobsById?id=" + (needEncode ? encodeId(id) : id), options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvJobsResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}