import {Component} from "react";
import {WindowParamContext} from "../../library/context/WindowContext";
import {SizeAttr} from "../../library/basic/size";
import {ColumnUnit} from "../../library/atomic/unit/ColumnUnit";
import {FontAttr} from "../../library/basic/font";
import {ColorAttr} from "../../library/basic/color";
import "../../assets/fonts/gina/stylesheet.css";
import loginBg from "../../assets/login/login_bg_starry.jpeg";
import loginPad from "../../assets/login/login_pad.jpeg";
import aiuniLogo from "../../assets/prompt/nav/cv_prompt_logo.svg";
import googleIcon from "../../assets/login/google_icon.svg";
import discordIcon from "../../assets/login/discord_icon.svg";
import emailIcon from "../../assets/login/email_icon.svg";
import verifyCodeIcon from "../../assets/login/verify_code_icon.svg";
import signInIcon from "../../assets/login/sign_in_icon.svg";
import discordInviteIcon from "../../assets/login/discord_invite_icon.svg";
import {RowUnit} from "../../library/atomic/unit/RowUnit";
import {BoxAttr, ColumnAttr, RowAttr} from "../../library/basic/compose";
import {ImageUnit} from "../../library/atomic/image/ImageUnit";
import {SpanText} from "../../library/atomic/text/SpanText";
import {largeRadius, mediumRadius} from "./display/layout";
import {BoxUnit} from "../../library/atomic/unit/BoxUnit";
import {SpanHoverText} from "../../library/atomic/text/SpanHoverText";
import "./TcCvLoginPage.css";
import {
    discordLogin,
    getBackUrl, getEmailAuth,
    getLoginAuth,
    getLoginSource,
    googleLogin,
    loginByEmailAndCode,
    loginByJoin,
    saveToken,
    sendEmailCode
} from "./util/account";
import {toLink} from "../../library/util/linkUtil";

type TcCvLoginPageState = {
    email: string
    verifyCode: string
    codeColdSeconds: number
    inviteCode: string
    emailFocus: boolean
    verifyCodeFocus: boolean
    inviteCodeFocus: boolean
}

export class TcCvLoginPage extends Component {
    static contextType = WindowParamContext;

    state: TcCvLoginPageState = {
        email: "",
        verifyCode: "",
        inviteCode: "",
        codeColdSeconds: 0,
        emailFocus: false,
        verifyCodeFocus: false,
        inviteCodeFocus: false
    }

    static readonly stateStorageKey = "Aiuni-Ai-TcCvLoginPage-State"

    // 保存组件状态
    saveState(state: TcCvLoginPageState) {
        sessionStorage.setItem(TcCvLoginPage.stateStorageKey, JSON.stringify(state))
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            this.saveState(this.state)
            if (callback !== undefined) {
                callback()
            }
        })
    }

    loadState(): TcCvLoginPageState | undefined {
        let storageState = localStorage.getItem(TcCvLoginPage.stateStorageKey)
        let loadedState = undefined
        if (storageState) {
            loadedState = JSON.parse(storageState)
            this.setState(loadedState)
        }
        return loadedState
    }

    countColdSeconds() {
        setTimeout(() => {
            if (this.state.codeColdSeconds > 0) {
                this.updateAndSaveState({
                    codeColdSeconds: this.state.codeColdSeconds - 1
                }, () => {
                    this.countColdSeconds()
                })
            }
        }, 1000)
    }

    componentDidMount() {
        let res = this.loadState()
        if (res != undefined) {
            this.setState(res)
        }
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;

                    // 是否输入邀请码界面
                    let needInviteCode = getLoginSource() && (getLoginAuth() || getEmailAuth())

                    let widthNum = wp.currWidth
                    let heightNum = wp.currHeight
                    let bw = widthNum / 1512
                    let bh = heightNum / 982
                    let padWidthNum = bh * 812
                    let padHeightNum = bh * 522

                    // 表单大小
                    let formWidthNum = bh * 448
                    if (formWidthNum < 448) {
                        formWidthNum = 448
                        bh = 1
                    }
                    let formHeightNum = bh * 651
                    let formBlockWidthNum = bh * 390
                    let formBlockHeightNum = bh * 56

                    let formHorizontalMargin = bh * 22
                    let formIconBlockWidth = bh * 38
                    let formInputWidth = formBlockWidthNum - 2 * formHorizontalMargin - formIconBlockWidth

                    return (
                        <ColumnUnit
                            customStyleAttr={{
                                "backgroundImage": `url(${loginBg})`,
                                "backgroundRepeat": 'no-repeat',
                                "backgroundSize": "cover"
                            }}
                            fontAttr={new FontAttr(wp, "", "", "\"Gina\"")}
                            colorAttr={new ColorAttr(wp, "#ffffff", "#010101")}
                            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>

                            <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                <ColumnUnit
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginRight": (74 * bh) + "px"
                                    })}
                                >
                                    <ImageUnit
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginBottom": "3px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, padWidthNum + "px", padHeightNum + "px")}
                                        url={loginPad}/>
                                    <SpanText
                                        colorAttr={new ColorAttr(wp, "#B1B1B1")}
                                        customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                                        fontAttr={new FontAttr(wp, "24px", "500")}
                                    >
                                        {"AI Generate Unique Vitality"}
                                    </SpanText>
                                </ColumnUnit>

                                <RowUnit
                                    colorAttr={new ColorAttr(wp, "", "#1A1A1B")}
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "borderRadius": largeRadius
                                    })}
                                    sizeAttr={new SizeAttr(wp, formWidthNum + "px", formHeightNum + "px")}
                                >
                                    {
                                        needInviteCode &&
                                        <ColumnUnit
                                            customStyleAttr={BoxAttr.getChildrenPositionObj()}
                                        >
                                            <ImageUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {})}
                                                sizeAttr={new SizeAttr(wp, (115 * bh) + "px", (57 * bh) + "px")}
                                                url={aiuniLogo}/>
                                            <SpanText
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "marginBottom": (44 * bh) + "px"
                                                })}
                                                colorAttr={new ColorAttr(wp, "#C3C3C3")}
                                                fontAttr={new FontAttr(wp, "24px", "500")}
                                            >
                                                {"welcome to aiuni.ai"}
                                            </SpanText>

                                            <SpanText
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "marginBottom": (12 * bh) + "px"
                                                })}
                                                colorAttr={new ColorAttr(wp, "#C5BFBF")}
                                                fontAttr={new FontAttr(wp, "22px", "500")}
                                            >
                                                {"Invite Code"}
                                            </SpanText>

                                            <RowUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "marginBottom": (30 * bh) + "px",
                                                    "position": "relative"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    key={"TcCvLoginPage-EmailInputRow-" + this.state.inviteCodeFocus}
                                                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                        "borderRadius": mediumRadius,
                                                        "border": this.state.inviteCodeFocus ? "1.2px solid #DEDEDE" : "1.2px solid #666666",
                                                        "position": "absolute",
                                                        "top": 0,
                                                        "zIndex": 1
                                                    })}
                                                    sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                                >
                                                </RowUnit>
                                                <input
                                                    onFocus={() => {
                                                        this.updateAndSaveState({inviteCodeFocus: true})
                                                    }}
                                                    onBlur={() => {
                                                        this.updateAndSaveState({inviteCodeFocus: false})
                                                    }}
                                                    value={this.state.inviteCode}
                                                    onChange={(event) => {
                                                        this.updateAndSaveState({
                                                            inviteCode: event.target.value
                                                        })
                                                    }}
                                                    placeholder={"Your Invite Code"}
                                                    style={{
                                                        "margin": "auto",
                                                        "textAlign": "center",
                                                        "width": formInputWidth,
                                                        "fontSize": "20px",
                                                        "fontWeight": "500",
                                                        "fontFamily": "\"Gina\"",
                                                        "color": "#B2B2B2",
                                                        "background": "transparent",
                                                        "border": "none",
                                                        "zIndex": 2
                                                    }}/>
                                            </RowUnit>

                                            <RowUnit
                                                onClick={() => {
                                                    if (this.state.inviteCode) {
                                                        loginByJoin({
                                                            auth_state: getLoginAuth(),
                                                            token: getEmailAuth(),
                                                            invite_code: this.state.inviteCode
                                                        }, (success, resp) => {
                                                            if (success && resp) {
                                                                saveToken(resp.data.token)
                                                                toLink(window.atob(getBackUrl()))
                                                            }
                                                        })
                                                    }
                                                }}
                                                needHover={true}
                                                colorAttr={new ColorAttr(wp, "#1E1E1E", "#D9D9D9")}
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "border": "1.2px solid #a5a5a5",
                                                    "marginBottom": (32 * bh) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                                        "transform": "translateX(-" + ((4.5 * bh)) + "px)"
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (30.32 * bh) + "px",
                                                            "marginRight": (10 * bh) + "px",
                                                            "transform": "translateY(-" + ((1.5 * bh)) + "px)"
                                                        })}
                                                        alt={"aiuni"}
                                                        src={signInIcon}/>
                                                    <SpanText
                                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "transform": "translateY(" + ((1.5 * bh)) + "px)"
                                                        })}
                                                        fontAttr={new FontAttr(wp, "24px", "800")}
                                                    >
                                                        {"Try Aiuni"}
                                                    </SpanText>
                                                </RowUnit>
                                            </RowUnit>

                                            {/*分割线*/}
                                            <RowUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "marginBottom": (28 * bh) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px")}
                                            >
                                                <BoxUnit
                                                    colorAttr={new ColorAttr(wp, "", "#434343")}
                                                    sizeAttr={new SizeAttr(wp, (160 * bh) + "px", "1px")}
                                                    customStyleAttr={RowAttr.getChildrenPositionObj()}/>
                                                <SpanText
                                                    customStyleAttr={BoxAttr.getChildrenPositionObj()}
                                                    fontAttr={new FontAttr(wp, "20px", "500")}
                                                    colorAttr={new ColorAttr(wp, "#6E6E6E")}
                                                >
                                                    {"OR"}
                                                </SpanText>
                                                <BoxUnit
                                                    colorAttr={new ColorAttr(wp, "", "#434343")}
                                                    sizeAttr={new SizeAttr(wp, (160 * bh) + "px", "1px")}
                                                    customStyleAttr={RowAttr.getChildrenPositionObj()}/>
                                            </RowUnit>

                                            <RowUnit
                                                onClick={() => {
                                                    window.open("https://discord.gg/aiuni", "_blank")
                                                }}
                                                needHover={true}
                                                colorAttr={new ColorAttr(wp, "", "#3A3A3A")}
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "border": "1.2px solid #616161"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                                        "transform": "translateX(-" + ((4.5 * bh)) + "px)"
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (28 * bh) + "px",
                                                            "marginRight": (10 * bh) + "px",
                                                            "transform": "translateY(-" + ((1.5 * bh)) + "px)"
                                                        })}
                                                        alt={"join discord"}
                                                        src={discordInviteIcon}/>
                                                    <SpanText
                                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "transform": "translateY(" + ((1.5 * bh)) + "px)"
                                                        })}
                                                        colorAttr={new ColorAttr(wp, "#C5C5C5")}
                                                        fontAttr={new FontAttr(wp, "24px", "800")}
                                                    >
                                                        {"Join Community"}
                                                    </SpanText>
                                                </RowUnit>
                                            </RowUnit>

                                            <SpanText
                                                colorAttr={new ColorAttr(wp, "#676767")}
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "marginTop": "13px"
                                                })}
                                                fontAttr={new FontAttr(wp, "24px", "500")}
                                            >
                                                {"contact us - ai@aiuni.ai"}
                                            </SpanText>

                                        </ColumnUnit>
                                    }

                                    {
                                        !needInviteCode &&
                                        <ColumnUnit
                                            customStyleAttr={BoxAttr.getChildrenPositionObj()}
                                        >
                                            <ImageUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {})}
                                                sizeAttr={new SizeAttr(wp, (115 * bh) + "px", (57 * bh) + "px")}
                                                url={aiuniLogo}/>
                                            <SpanText
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "marginBottom": (23 * bh) + "px"
                                                })}
                                                colorAttr={new ColorAttr(wp, "#C3C3C3")}
                                                fontAttr={new FontAttr(wp, "24px", "500")}
                                            >
                                                {"welcome to aiuni.ai"}
                                            </SpanText>

                                            <RowUnit
                                                onClick={() => {
                                                    googleLogin()
                                                }}
                                                classname={"login-form-block"}
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "border": "1.2px solid #666666",
                                                    "marginBottom": (20 * bh) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    sizeAttr={new SizeAttr(wp, formIconBlockWidth + "px")}
                                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                        "marginLeft": formHorizontalMargin
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (24.19 * bh) + "px"
                                                        })}
                                                        alt={"google"}
                                                        src={googleIcon}/>
                                                </RowUnit>
                                                <SpanText
                                                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                                                    fontAttr={new FontAttr(wp, "20px", "500")}
                                                    colorAttr={new ColorAttr(wp, "#B2B2B2")}
                                                >
                                                    {"Continue with Google"}
                                                </SpanText>
                                            </RowUnit>

                                            <RowUnit
                                                onClick={() => {
                                                    discordLogin()
                                                }}
                                                classname={"login-form-block"}
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "border": "1.2px solid #666666",
                                                    "marginBottom": (20 * bh) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    sizeAttr={new SizeAttr(wp, formIconBlockWidth + "px")}
                                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                        "marginLeft": formHorizontalMargin
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (26.2 * bh) + "px"
                                                        })}
                                                        alt={"discord"}
                                                        src={discordIcon}/>
                                                </RowUnit>
                                                <SpanText
                                                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                                                    fontAttr={new FontAttr(wp, "20px", "500")}
                                                    colorAttr={new ColorAttr(wp, "#B2B2B2")}
                                                >
                                                    {"Continue with Discord"}
                                                </SpanText>
                                            </RowUnit>

                                            {/*分割线*/}
                                            <RowUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "marginBottom": (20 * bh) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px")}
                                            >
                                                <BoxUnit
                                                    colorAttr={new ColorAttr(wp, "", "#434343")}
                                                    sizeAttr={new SizeAttr(wp, (160 * bh) + "px", "1px")}
                                                    customStyleAttr={RowAttr.getChildrenPositionObj()}/>
                                                <SpanText
                                                    customStyleAttr={BoxAttr.getChildrenPositionObj()}
                                                    fontAttr={new FontAttr(wp, "20px", "500")}
                                                    colorAttr={new ColorAttr(wp, "#6E6E6E")}
                                                >
                                                    {"OR"}
                                                </SpanText>
                                                <BoxUnit
                                                    colorAttr={new ColorAttr(wp, "", "#434343")}
                                                    sizeAttr={new SizeAttr(wp, (160 * bh) + "px", "1px")}
                                                    customStyleAttr={RowAttr.getChildrenPositionObj()}/>
                                            </RowUnit>

                                            <RowUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "marginBottom": (20 * bh) + "px",
                                                    "position": "relative"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    key={"TcCvLoginPage-EmailInputRow-" + this.state.emailFocus}
                                                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                        "borderRadius": mediumRadius,
                                                        "border": this.state.emailFocus ? "1.2px solid #DEDEDE" : "1.2px solid #666666",
                                                        "position": "absolute",
                                                        "top": 0,
                                                        "zIndex": 1
                                                    })}
                                                    sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                                >
                                                </RowUnit>
                                                <RowUnit
                                                    sizeAttr={new SizeAttr(wp, formIconBlockWidth + "px")}
                                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                        "marginLeft": formHorizontalMargin
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (26.67 * bh) + "px"
                                                        })}
                                                        alt={"email"}
                                                        src={emailIcon}/>
                                                </RowUnit>
                                                <input
                                                    onFocus={() => {
                                                        this.updateAndSaveState({emailFocus: true})
                                                    }}
                                                    onBlur={() => {
                                                        this.updateAndSaveState({emailFocus: false})
                                                    }}
                                                    value={this.state.email}
                                                    onChange={(event) => {
                                                        this.updateAndSaveState({
                                                            email: event.target.value
                                                        })
                                                    }}
                                                    placeholder={"Your Email Address"}
                                                    style={{
                                                        "marginTop": "auto",
                                                        "marginBottom": "auto",
                                                        "width": formInputWidth,
                                                        "fontSize": "20px",
                                                        "fontWeight": "500",
                                                        "fontFamily": "\"Gina\"",
                                                        "color": "#B2B2B2",
                                                        "background": "transparent",
                                                        "border": "none",
                                                        "zIndex": 2
                                                    }}/>
                                            </RowUnit>

                                            <RowUnit
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "marginBottom": (4 * bh) + "px",
                                                    "position": "relative"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    key={"TcCvLoginPage-VerifyCodeInputRow-" + this.state.verifyCodeFocus}
                                                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                        "borderRadius": mediumRadius,
                                                        "border": this.state.verifyCodeFocus ? "1.2px solid #DEDEDE" : "1.2px solid #666666",
                                                        "position": "absolute",
                                                        "top": 0,
                                                        "zIndex": 1
                                                    })}
                                                    sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                                >
                                                </RowUnit>
                                                <RowUnit
                                                    sizeAttr={new SizeAttr(wp, formIconBlockWidth + "px")}
                                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                        "marginLeft": formHorizontalMargin
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (28 * bh) + "px"
                                                        })}
                                                        alt={"verifyCode"}
                                                        src={verifyCodeIcon}/>
                                                </RowUnit>
                                                <input
                                                    onFocus={() => {
                                                        this.updateAndSaveState({verifyCodeFocus: true})
                                                    }}
                                                    onBlur={() => {
                                                        this.updateAndSaveState({verifyCodeFocus: false})
                                                    }}
                                                    value={this.state.verifyCode}
                                                    onChange={(event) => {
                                                        this.updateAndSaveState({
                                                            verifyCode: event.target.value
                                                        })
                                                    }}
                                                    placeholder={"Verify Code"}
                                                    style={{
                                                        "marginTop": "auto",
                                                        "marginBottom": "auto",
                                                        "width": formInputWidth,
                                                        "fontSize": "20px",
                                                        "fontWeight": "500",
                                                        "fontFamily": "\"Gina\"",
                                                        "color": "#B2B2B2",
                                                        "background": "transparent",
                                                        "border": "none",
                                                        "zIndex": 2
                                                    }}/>
                                            </RowUnit>

                                            <SpanHoverText
                                                onClick={() => {
                                                    if (this.state.codeColdSeconds == 0) {
                                                        sendEmailCode({email: this.state.email}, (success, resp) => {
                                                            if (success && resp) {
                                                                this.updateAndSaveState({
                                                                    codeColdSeconds: 60
                                                                }, () => {
                                                                    this.countColdSeconds()
                                                                })
                                                            }
                                                        })
                                                    }
                                                }}
                                                customStyleAttr={{
                                                    "marginLeft": "auto",
                                                    "marginRight": (4 * bh) + "px",
                                                    "marginBottom": (20 * bh) + "px"
                                                }}
                                                colorAttr={new ColorAttr(wp, "#939393")}
                                                fontAttr={new FontAttr(wp, "20px", "500")}
                                            >
                                                {"send code" + (this.state.codeColdSeconds > 0 ? ("(" + this.state.codeColdSeconds + ")") : "")}
                                            </SpanHoverText>

                                            <RowUnit
                                                onClick={() => {
                                                    if (this.state.email && this.state.verifyCode) {
                                                        loginByEmailAndCode({
                                                            email: this.state.email,
                                                            sms_code: this.state.verifyCode
                                                        }, (success, resp) => {
                                                            if (success && resp) {
                                                                saveToken(resp.data.token)
                                                                toLink(window.atob(getBackUrl()))
                                                            } else if (resp && resp.error == 401) {
                                                                window.location.href='/3d/#/signup/complation?token='+resp.data.token
                                                               // window.location.href = "/login?source=email&auth=" + resp.data.token
                                                            }
                                                        })
                                                    }
                                                }}
                                                needHover={true}
                                                colorAttr={new ColorAttr(wp, "#1E1E1E", "#D9D9D9")}
                                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                    "borderRadius": mediumRadius,
                                                    "border": "1.2px solid #a5a5a5"
                                                })}
                                                sizeAttr={new SizeAttr(wp, formBlockWidthNum + "px", formBlockHeightNum + "px")}
                                            >
                                                <RowUnit
                                                    customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                                        "transform": "translateX(-" + ((4.5 * bh)) + "px)"
                                                    })}
                                                >
                                                    <img
                                                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "width": (30.32 * bh) + "px",
                                                            "marginRight": (10 * bh) + "px",
                                                            "transform": "translateY(-" + ((1.5 * bh)) + "px)"
                                                        })}
                                                        alt={"aiuni"}
                                                        src='https://cdn.aiuni.ai/web/images/aiuni-robot.png!w64' />
                                                    <SpanText
                                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                            "transform": "translateY(" + ((1.5 * bh)) + "px)"
                                                        })}
                                                        fontAttr={new FontAttr(wp, "24px", "800")}
                                                    >
                                                        {"Sign In"}
                                                    </SpanText>
                                                </RowUnit>
                                            </RowUnit>
                                        </ColumnUnit>
                                    }
                                </RowUnit>
                            </RowUnit>

                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}