import {Component} from "react";
import {WindowParamContext} from "../../../library/context/WindowContext";
import {BoxUnit} from "../../../library/atomic/unit/BoxUnit";
import {SizeAttr} from "../../../library/basic/size";
import "./CategoryButtonPcAndPad.css"
import {SpanText} from "../../../library/atomic/text/SpanText";
import {BoxAttr} from "../../../library/basic/compose";
import {FontAttr} from "../../../library/basic/font";

export type CategoryButtonPcAndPadProps = {
    text: string
    selected: boolean
    width?: string
    height?: string
    buttonClick: () => void
}

export class CategoryButtonPcAndPad extends Component<CategoryButtonPcAndPadProps> {
    static contextType = WindowParamContext;

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;

                    return (
                        <BoxUnit
                            classname={`category-button-pc-and-pad ${this.props.selected ? 'clicked' : ''}`}
                            onClick={() => {
                                this.props.buttonClick()
                            }}
                            customStyleAttr={{
                                "borderRadius": "8px",
                                "marginRight": "8px",
                                "minWidth": "99px"
                            }}
                            sizeAttr={new SizeAttr(wp, this.props.width ? this.props.width : "99px",
                                this.props.height ? this.props.height : "32px")}>
                            <SpanText
                                customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                    "transform": "translateY(2px)"
                                })}
                                fontAttr={new FontAttr(wp, "18px", "600")}
                            >
                                {this.props.text}
                            </SpanText>
                        </BoxUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}