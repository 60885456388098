import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {ColorAttr} from "../../../../library/basic/color";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import createProjectButton from "../../../../assets/user/menu/create_project_button.svg";
import {ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {FontAttr} from "../../../../library/basic/font";
import universeSelectedIcon from "../../../../assets/user/menu/universe_selected_icon.svg";
import mineSelectedIcon from "../../../../assets/user/menu/mine_selected_icon.svg";
import mineUnselectedIcon from "../../../../assets/user/menu/mine_unselected_icon.svg";
import characterIcon from "../../../../assets/user/menu/character_icon.svg";
import templateIcon from "../../../../assets/user/menu/template_icon.svg";
import animationIcon from "../../../../assets/user/menu/animation_icon.svg";
import modelIcon from "../../../../assets/user/menu/model_icon.svg";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {mediumPadding} from "../../display/layout";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import "./TcCvUserLeftMenu.css";
import bDiscord from "../../../../assets/user/menu/b_discord_icon.svg";
import bIns from "../../../../assets/user/menu/b_ins_icon.svg";
import bTwitter from "../../../../assets/user/menu/b_twitter_icon.svg";
import bMail from "../../../../assets/user/menu/b_mail_icon.svg";
import {getToken, toLoginPage} from "../../util/account";
import {isHomePage, toLink} from "../../../../library/util/linkUtil";
import {useNavigate} from "react-router";

interface TcCvUserLeftMenuProps {
    wp: WindowParam
    menuHeight: string
    showGenerationPopup: () => void
}

export const TcCvUserLeftMenu: FC<TcCvUserLeftMenuProps> = ({wp, menuHeight, showGenerationPopup}: TcCvUserLeftMenuProps) => {
    let innerWidth = "224px"

    let createButtonTopMargin = "40px"
    let createButtonHeight = "48px"
    let createButtonBottomMargin = "32px"

    let switchButtonTopMargin = wp.currHeight > 960 ? "40px" : "18px"
    let bottomCompanyColHeight = (24 + 72) + "px"

    // 36 + 48 + 32 + 24 + 72 = 212 (84 + 32 + 96)
    let middleColHeight = SizeAttr.pxMinus(menuHeight, "212px")

    const navigate = useNavigate();

    return (
        <ColumnUnit
            colorAttr={new ColorAttr(wp, "", "#141414")}
            sizeAttr={new SizeAttr(wp, "256px", menuHeight)}>

            <ImageUnit
                needHover={true}
                onClick={() => {
                    if (!getToken()) {
                        toLoginPage(window.location.pathname, navigate)
                        return
                    }

                    showGenerationPopup()
                }}
                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "marginTop": createButtonTopMargin,
                    "marginBottom": createButtonBottomMargin
                })}
                sizeAttr={new SizeAttr(wp, innerWidth, createButtonHeight)}
                url={createProjectButton}/>

            <ColumnUnit
                classname={"tc-cv-user-left-menu-container"}
                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    // "overflowY": "auto",
                    "maxWidth": innerWidth
                })}
                sizeAttr={new SizeAttr(wp, innerWidth, middleColHeight)}
            >
                <ColumnUnit
                    sizeAttr={new SizeAttr(wp, innerWidth)}
                    customStyleAttr={{
                        "marginTop": switchButtonTopMargin,
                        "marginBottom": "40px"
                    }}
                >
                    <MenuMainButton
                        wp={wp}
                        selected={isHomePage()}
                        icon={universeSelectedIcon}
                        selectedIcon={universeSelectedIcon}
                        text={"Universe"}
                        targetPath={"/"}/>
                    <MenuMainButton
                        wp={wp}
                        selected={window.location.href.indexOf("mine") >= 0}
                        icon={mineUnselectedIcon}
                        selectedIcon={mineSelectedIcon}
                        text={"Mine"}
                        targetPath={"/mine"}/>
                </ColumnUnit>

                <ColumnUnit
                    sizeAttr={new SizeAttr(wp, innerWidth)}
                    customStyleAttr={{
                        "marginBottom": "18px"
                    }}
                >
                    <SpanText
                        customStyleAttr={{
                            "justifyContent": "",
                            "marginBottom": "6px"
                        }}
                        colorAttr={new ColorAttr(wp, "#595959")}
                        fontAttr={new FontAttr(wp, "14", "600")}>
                        {"AI Assets"}
                    </SpanText>
                    <MenuSecondButton
                        wp={wp}
                        selected={false}
                        icon={characterIcon}
                        text={"Character"}
                        targetPath={"/prompt/character"}/>
                    <MenuSecondButton
                        wp={wp}
                        selected={false}
                        icon={templateIcon}
                        text={"Template"}
                        targetPath={"/prompt/template"}/>
                    <MenuSecondButton
                        wp={wp}
                        selected={false}
                        icon={animationIcon}
                        text={"Animation"}
                        targetPath={""}/>
                    <MenuSecondButton
                        wp={wp}
                        selected={false}
                        icon={modelIcon}
                        text={"3D Model"}
                        targetPath={"/3d"}/>
                </ColumnUnit>
            </ColumnUnit>

            <ColumnUnit
                customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, innerWidth, bottomCompanyColHeight)}
            >
                <RowUnit sizeAttr={new SizeAttr(wp, innerWidth, "28px")}>
                    <ImageUnit
                        customStyleAttr={{"marginRight": "auto"}}
                        sizeAttr={new SizeAttr(wp, "28px", "28px")}
                        url={bDiscord} />
                    <ImageUnit
                        customStyleAttr={{"marginRight": "auto"}}
                        sizeAttr={new SizeAttr(wp, "28px", "28px")}
                        url={bIns} />
                    <ImageUnit
                        customStyleAttr={{"marginRight": "auto"}}
                        sizeAttr={new SizeAttr(wp, "28px", "28px")}
                        url={bTwitter} />
                    <ImageUnit
                        sizeAttr={new SizeAttr(wp, "28px", "28px")}
                        url={bMail} />
                </RowUnit>

                <BoxUnit
                    sizeAttr={new SizeAttr(wp, innerWidth, "1px")}
                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                        "marginTop": "18px",
                        "marginBottom": "8px",
                        "background": "#4B4A50"
                    })}
                />

                <SpanText
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    fontAttr={new FontAttr(wp, "14", "", "'Inter'")}
                    colorAttr={new ColorAttr(wp, "#767676")}>
                    {"aiuni.ai"}
                </SpanText>

            </ColumnUnit>
        </ColumnUnit>
    )
}

interface MenuMainButtonProps {
    wp: WindowParam
    selected: boolean
    icon: string
    selectedIcon: string
    text: string
    targetPath: string
}

const MenuMainButton: FC<MenuMainButtonProps> = ({
                                                     wp,
                                                     selected,
                                                     icon,
                                                     selectedIcon,
                                                     text,
                                                     targetPath
                                                 }: MenuMainButtonProps) => {
    const navigate = useNavigate();

    return (
        <ColumnUnit
            customStyleAttr={{
                "marginBottom": "16px",
                "position": "relative",
                "maxWidth": "224px",
                "overflow": "hidden",
                "borderRadius": "10px",
            }}
            sizeAttr={new SizeAttr(wp, "224px", "46px")}
        >

            {
                selected && <BoxUnit
                    sizeAttr={new SizeAttr(wp, "224px", "4px")}
                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                        "background": "linear-gradient(to right, #4DD1C19A 0%, #4F78C89A 26%, #8F51B89A 51%, #BE57819A 76%, #BF73539A 100%)",
                        "borderRadius": "2px",
                        "position": "absolute",
                        "bottom": "0"
                    })}
                />
            }

            <RowUnit
                needHover={true}
                onClick={() => {
                    if (!selected) {
                        if (!getToken()) {
                            toLoginPage(window.location.pathname, navigate)
                            return
                        }

                        toLink(targetPath, navigate)
                    }
                }}
                customStyleAttr={{
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    "borderRadius": "10px",
                    "background": "#1c1c1c",
                    "border": "1px solid #2c2c2c",
                    "boxShadow": selected ? "0 4px 4px 0 rgba(153, 153, 153, 0.25)" : "",
                }}
                sizeAttr={new SizeAttr(wp, "222px", selected ? "40px" : "44px")}>
                <ImageUnit
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginLeft": "18px",
                        "marginRight": "12px",
                        "transform": selected ? "translateY(0px)" : "translateY(0px)"
                    })}
                    sizeAttr={new SizeAttr(wp, "24px", "24px")}
                    url={selected ? selectedIcon : icon}/>
                <SpanText
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "transform":"translateY(2px)"
                    })}
                    colorAttr={new ColorAttr(wp, selected ? "#fafafa" : "#898B94")}
                    fontAttr={new FontAttr(wp, "16px", "500")}
                >
                    {text}
                </SpanText>
            </RowUnit>
        </ColumnUnit>
    )
}


interface MenuSecondButtonProps {
    wp: WindowParam
    selected: boolean
    icon: string
    text: string
    targetPath: string
}

const MenuSecondButton: FC<MenuSecondButtonProps> = ({
                                                         wp,
                                                         selected,
                                                         icon,
                                                         text,
                                                         targetPath
                                                     }: MenuSecondButtonProps) => {
    const navigate = useNavigate();

    return (
        <RowUnit
            onClick={() => {
                if (!selected) {
                    if (!getToken()) {
                        toLoginPage(targetPath, navigate)
                        return
                    }
                    if (targetPath) {
                        window.open(targetPath, "_blank")
                    }
                }
            }}
            customStyleAttr={{
                "borderRadius": "10px",
                "marginBottom": mediumPadding,
                "maxWidth": "224px"
            }}
            classname={"tc-cv-user-left-menu-second-button"}
            sizeAttr={new SizeAttr(wp, "224px", "40px")}>
            <ImageUnit
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginLeft": "18px",
                    "marginRight": "12px",
                })}
                sizeAttr={new SizeAttr(wp, "24px", "24px")}
                url={icon}/>
            <SpanText
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "transform": "translateY(2px)"
                })}
                colorAttr={new ColorAttr(wp, "#898B94")}
                fontAttr={new FontAttr(wp, "16px", "500")}
            >
                {text}
            </SpanText>
        </RowUnit>
    )
}