import * as React from "react";
import {Component} from "react";
import {WindowParam, WindowParamContext} from "../../library/context/WindowContext";
import {SizeAttr} from "../../library/basic/size";
import {ColumnUnit} from "../../library/atomic/unit/ColumnUnit";
import {FontAttr} from "../../library/basic/font";
import {ColorAttr} from "../../library/basic/color";
import "../../assets/fonts/gina/stylesheet.css";
import {TcCvUserTopNavPc, TcCvUserTopNavPhone} from "./component/user/TcCvUserTopNav";
import {RowUnit} from "../../library/atomic/unit/RowUnit";
import {TcCvUserLeftMenu} from "./component/user/TcCvUserLeftMenu";
import {TcCvUserMinePanel} from "./component/user/TcCvUserMinePanel";
import {TcCvMixVideosData} from "./data/TcCvMixVideos";
import {TcCvCharactersData} from "./data/TcCvCharacters";
import {TcCvTemplatesData} from "./data/TcCvTemplates";
import {TcCvUserHomePanel} from "./component/user/TcCvHomePanel";
import {PopupBaseBox} from "./component/popup/PopupBaseBox";
import {MixVideoPopup} from "./component/popup/MixVideoPopup";
import {GenerationPopup} from "./component/popup/GenerationPopup";
import {CardSelectPopup, CardSelectPopupProps} from "./component/popup/CardSelectPopup";
import {getUserAvatar, getUsername, toLoginPage} from "./util/account";
import {UploadCharacterGuidePopup, UploadTemplateGuidePopup} from "./component/popup/UploadGuidePopup";
import {LabIndex} from "./TcCvPromptPage";
import {TemplatePopup} from "./component/popup/TemplatePopup";
import {CharacterPopup} from "./component/popup/CharacterPopup";
import {getDisplayMode} from "./display/layout";
import {
    TcCharacterDetailPanelPadAndPhoneBox,
    TcMixVideoDetailPanelPadAndPhoneBox,
    TcTemplateDetailPanelPadAndPhoneBox
} from "./component/DetailPanelPadAndPhone";
import phoneCharacterCopyIcon from "../../assets/user/popup/generate/phone_character_copy_icon.svg";
import phoneTemplateCopyIcon from "../../assets/user/popup/generate/phone_template_copy_icon.svg";
import phoneMixVideoCopyIcon from "../../assets/user/popup/generate/phone_mix_video_copy_icon.svg";
import {TcCvUserBottomNav} from "./component/user/TcCvUserBottomNav";
import {TcCvUserHomePanelPhone} from "./component/user/TcCvHomePanelPhone";
import {queryUserInfo} from "./data/TcCvUser";
import {isHomePage} from "../../library/util/linkUtil";

export type GenerationPopupInitProps = {
    character: TcCvCharactersData | undefined,
    template: TcCvTemplatesData | undefined
}

type TcCvMainPageState = {
    avatar: string
    username: string
    showUpgradePlan: boolean
    uploadGuide: LabIndex | undefined
    generationPopupProps: GenerationPopupInitProps | undefined
    cardSelectPopupProps: CardSelectPopupProps | undefined
    detailMixVideo: TcCvMixVideosData | undefined
    detailCharacter: TcCvCharactersData | undefined
    detailTemplate: TcCvTemplatesData | undefined
    newMixVideoCallback: (() => void) | undefined
    newCharacterCallback: ((character: TcCvCharactersData) => void) | undefined
    newTemplateCallback: ((template: TcCvTemplatesData) => void) | undefined
    characterConfirm: ((d: TcCvCharactersData) => void) | undefined
    templateConfirm: ((d: TcCvTemplatesData) => void) | undefined
}

export class TcCvMainPage extends Component {
    static contextType = WindowParamContext;

    state: TcCvMainPageState = {
        avatar: getUserAvatar(),
        username: getUsername(),
        showUpgradePlan: false,
        uploadGuide: undefined,
        generationPopupProps: undefined,
        cardSelectPopupProps: undefined,
        detailMixVideo: undefined,
        detailCharacter: undefined,
        detailTemplate: undefined,
        newMixVideoCallback: undefined,
        newCharacterCallback: undefined,
        newTemplateCallback: undefined,
        characterConfirm: undefined,
        templateConfirm: undefined
    }

    static readonly stateStorageKey = "Aiuni-Ai-TcCvMainPage-State"

    // 保存组件状态
    saveState(state: TcCvMainPageState) {
        sessionStorage.setItem(TcCvMainPage.stateStorageKey, JSON.stringify(state))
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            this.saveState(this.state)
            if (callback !== undefined) {
                callback()
            }
        })
    }

    wp: WindowParam = new WindowParam()

    componentDidMount() {

        // 非首页，直接跳转登录
        queryUserInfo({}, (success, resp) => {
            if (success && resp) {
                this.updateAndSaveState({username: resp.data.user.nickname, avatar: resp.data.user.picture})
            } else {
                // 非登录态，非首页，跳转登录
                if (!isHomePage()) {
                    toLoginPage(window.location.href)
                    return
                }
            }
        })
    }

    showMixVideoDetailCard(d: TcCvMixVideosData) {
        this.updateAndSaveState({detailMixVideo: d})
    }

    showCharacterDetailCard(d: TcCvCharactersData) {
        this.updateAndSaveState({detailCharacter: d})
    }

    showTemplateDetailCard(d: TcCvTemplatesData) {
        this.updateAndSaveState({detailTemplate: d})
    }

    showGenerationPopup(d: GenerationPopupInitProps) {
        this.updateAndSaveState({generationPopupProps: d})
    }

    triggerUpload(labIndex: LabIndex) {
        this.updateAndSaveState({uploadGuide: labIndex})
    }

    triggerUploadCharacterData(character: TcCvCharactersData) {
        // console.log("triggerUploadCharacterData", character)
        this.updateAndSaveState({uploadGuide: undefined}, () => {
            if (this.state.cardSelectPopupProps == undefined) {
                // 我的角色弹窗未打开
                this.updateAndSaveState({
                    cardSelectPopupProps: {
                        wp: this.wp,
                        labIndex: "character",
                        selectSource: "self",
                        dataConfirm: (d: any) => {
                            if (this.state.characterConfirm) {
                                this.state.characterConfirm(d as TcCvCharactersData)
                            }
                        },
                        triggerUpload: (labIndex: LabIndex) => {
                            this.triggerUpload(labIndex)
                        },
                        registerDataCallback: (characterCallback: (character: TcCvCharactersData) => void) => {
                            this.updateAndSaveState({newCharacterCallback: characterCallback})
                        },
                        closeClick: () => {
                            this.updateAndSaveState({cardSelectPopupProps: undefined})
                        }
                    }
                }, () => {
                    setTimeout(() => {
                        if (this.state.newCharacterCallback) {
                            this.state.newCharacterCallback(character)
                        }
                    }, 200)
                })
            } else {
                // 我的角色弹窗已打开
                if (this.state.newCharacterCallback) {
                    this.state.newCharacterCallback(character)
                }
            }
        })
    }

    triggerUploadTemplateData(template: TcCvTemplatesData) {
        // console.log("triggerUploadTemplateData", template)
        this.updateAndSaveState({uploadGuide: undefined}, () => {
            if (this.state.cardSelectPopupProps == undefined) {
                // 我的模版弹窗未打开
                this.updateAndSaveState({
                    cardSelectPopupProps: {
                        wp: this.wp,
                        labIndex: "template",
                        selectSource: "self",
                        dataConfirm: (d: any) => {
                            if (this.state.templateConfirm) {
                                this.state.templateConfirm(d as TcCvTemplatesData)
                            }
                        },
                        triggerUpload: (labIndex: LabIndex) => {
                            this.triggerUpload(labIndex)
                        },
                        registerDataCallback: (templateCallback: (template: TcCvTemplatesData) => void) => {
                            this.updateAndSaveState({newTemplateCallback: templateCallback})
                        },
                        closeClick: () => {
                            this.updateAndSaveState({cardSelectPopupProps: undefined})
                        }
                    }
                }, () => {
                    setTimeout(() => {
                        if (this.state.newTemplateCallback) {
                            this.state.newTemplateCallback(template)
                        }
                    }, 200)
                })
            } else {
                // 我的角色弹窗已打开
                if (this.state.newTemplateCallback) {
                    this.state.newTemplateCallback(template)
                }
            }
        })
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;
                    this.wp = wpt.param;

                    let navHeight = "64px"
                    let menuWidth = "256px"

                    let panelHeight = (wp.currHeight - SizeAttr.getPxNumber(navHeight)) + "px"
                    let panelWidth = SizeAttr.pxMinus(SizeAttr.changeVwToPx(wp, "100vw"), menuWidth)

                    let that = this

                    return (
                        <ColumnUnit
                            fontAttr={new FontAttr(wp, "", "", "\"Gina\"")}
                            colorAttr={new ColorAttr(wp, "#ffffff", "#000000")}
                            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>

                            {/* 计划升级面板 */}

                            {/* 生成弹窗 */}
                            {
                                this.state.generationPopupProps &&
                                PopupBaseBox(wp, (closeClick) => {
                                    return <GenerationPopup
                                        wp={wp}
                                        setCardSelectPopupProps={(p) => {
                                            this.updateAndSaveState({cardSelectPopupProps: p})
                                        }}
                                        triggerUpload={(labIndex) => {
                                            this.triggerUpload(labIndex)
                                        }}
                                        closeClick={closeClick}
                                        registerCharacterConfirm={(dc) => {
                                            this.updateAndSaveState({characterConfirm: dc})
                                        }}
                                        registerTemplateConfirm={(dc) => {
                                            this.updateAndSaveState({templateConfirm: dc})
                                        }}
                                        registerCharacterDataCallback={(dataCallback: (character: TcCvCharactersData) => void) => {
                                            this.updateAndSaveState({newCharacterCallback: dataCallback})
                                        }}
                                        registerTemplateDataCallback={(dataCallback: (template: TcCvTemplatesData) => void) => {
                                            this.updateAndSaveState({newTemplateCallback: dataCallback})
                                        }}
                                        initProps={this.state.generationPopupProps ? this.state.generationPopupProps
                                            : {character: undefined, template: undefined}}
                                    />
                                }, (triggerTask: boolean) => {
                                    that.updateAndSaveState({
                                        generationPopupProps: undefined,
                                        characterConfirm: undefined,
                                        templateConfirm: undefined
                                    })
                                    if (triggerTask && this.state.newMixVideoCallback) {
                                        this.state.newMixVideoCallback()
                                    }
                                })
                            }
                            {
                                this.state.cardSelectPopupProps &&
                                PopupBaseBox(wp, (closeClick) => {
                                    if (!this.state.cardSelectPopupProps) {
                                        return <div></div>
                                    }
                                    return <CardSelectPopup {...this.state.cardSelectPopupProps} />
                                }, () => {
                                    that.updateAndSaveState({cardSelectPopupProps: undefined})
                                }, "1000")
                            }

                            {/* 上传提示弹窗 */}
                            {
                                this.state.uploadGuide == "character" &&
                                PopupBaseBox(wp, (closeClick) => {
                                    return <UploadCharacterGuidePopup
                                        wp={wp}
                                        closeClick={closeClick}
                                        uploadClick={(d) => {
                                            this.triggerUploadCharacterData(d)
                                        }}
                                    />
                                }, () => {
                                    that.updateAndSaveState({uploadGuide: undefined})
                                }, "1001")
                            }
                            {
                                this.state.uploadGuide == "template" &&
                                PopupBaseBox(wp, (closeClick) => {
                                    return <UploadTemplateGuidePopup
                                        wp={wp}
                                        closeClick={closeClick}
                                        uploadClick={(d) => {
                                            this.triggerUploadTemplateData(d)
                                        }}
                                    />
                                }, () => {
                                    that.updateAndSaveState({uploadGuide: undefined})
                                }, "1001")
                            }

                            {/* 详情弹窗 */}
                            {
                                this.state.detailMixVideo && getDisplayMode(wp.currWidth) != "pc" &&
                                TcMixVideoDetailPanelPadAndPhoneBox(wp, this.state.detailMixVideo,
                                    () => {
                                    },
                                    () => {
                                        this.updateAndSaveState({detailMixVideo: undefined})
                                    }, phoneMixVideoCopyIcon,
                                    () => {
                                        let t = this.state.detailMixVideo
                                        this.updateAndSaveState({
                                            generationPopupProps: {
                                                character: t?.character,
                                                template: t?.template
                                            },
                                            detailMixVideo: undefined
                                        })
                                    }
                                )
                            }
                            {
                                this.state.detailTemplate && getDisplayMode(wp.currWidth) != "pc" &&
                                TcTemplateDetailPanelPadAndPhoneBox(wp, this.state.detailTemplate,
                                    () => {
                                    },
                                    () => {
                                        this.updateAndSaveState({detailTemplate: undefined})
                                    }, phoneTemplateCopyIcon,
                                    () => {
                                        let t = this.state.detailTemplate
                                        this.updateAndSaveState({
                                            generationPopupProps: {
                                                character: undefined,
                                                template: t
                                            },
                                            detailTemplate: undefined
                                        })
                                    }
                                )
                            }
                            {
                                this.state.detailCharacter && getDisplayMode(wp.currWidth) != "pc" &&
                                TcCharacterDetailPanelPadAndPhoneBox(wp, this.state.detailCharacter,
                                    () => {
                                    },
                                    () => {
                                        this.updateAndSaveState({detailCharacter: undefined})
                                    }, phoneCharacterCopyIcon,
                                    () => {
                                        let c = this.state.detailCharacter
                                        this.updateAndSaveState({
                                            generationPopupProps: {
                                                character: c,
                                                template: undefined
                                            },
                                            detailCharacter: undefined
                                        })
                                    }
                                )
                            }
                            {
                                this.state.detailMixVideo && getDisplayMode(wp.currWidth) == "pc" &&
                                PopupBaseBox(wp, (closeClick) => {
                                    return <MixVideoPopup
                                        wp={wp}
                                        mixVideo={this.state.detailMixVideo as TcCvMixVideosData}
                                        closeClick={() => {
                                            closeClick(false)
                                        }}
                                        showCharacterDetailCard={(d) => {
                                            this.showCharacterDetailCard(d)
                                        }}
                                        showTemplateDetailCard={(d) => {
                                            this.showTemplateDetailCard(d)
                                        }}
                                        showGenerationPopup={(d) => {
                                            this.showGenerationPopup(d)
                                        }}
                                    />
                                }, () => {
                                    that.updateAndSaveState({detailMixVideo: undefined})
                                })
                            }
                            {
                                this.state.detailTemplate && getDisplayMode(wp.currWidth) == "pc" &&
                                PopupBaseBox(wp, (closeClick) => {
                                    return <TemplatePopup
                                        wp={wp}
                                        template={this.state.detailTemplate as TcCvTemplatesData}
                                        closeClick={() => {
                                            closeClick(false)
                                        }}
                                        showMixVideoDetailCard={(d) => {
                                            this.showMixVideoDetailCard(d)
                                        }}
                                        showGenerationPopup={(d) => {
                                            this.showGenerationPopup(d)
                                        }}
                                    />
                                }, () => {
                                    that.updateAndSaveState({detailTemplate: undefined})
                                }, "998")
                            }
                            {
                                this.state.detailCharacter && getDisplayMode(wp.currWidth) == "pc" &&
                                PopupBaseBox(wp, (closeClick) => {
                                    return <CharacterPopup
                                        wp={wp}
                                        character={this.state.detailCharacter as TcCvCharactersData}
                                        closeClick={() => {
                                            closeClick(false)
                                        }}
                                        showMixVideoDetailCard={(d) => {
                                            this.showMixVideoDetailCard(d)
                                        }}
                                        showGenerationPopup={(d) => {
                                            this.showGenerationPopup(d)
                                        }}
                                    />
                                }, () => {
                                    that.updateAndSaveState({detailCharacter: undefined})
                                }, "998")
                            }

                            {
                                getDisplayMode(wp.currWidth) == "mobile" &&
                                <ColumnUnit sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
                                    {/* 主导航 */}
                                    <TcCvUserTopNavPhone
                                        key={"TcCvUserTopNavPhone-" + this.state.avatar + "-" + this.state.username}
                                        wp={wp}
                                        navHeight={"48px"}
                                        avatar={this.state.avatar}
                                        username={this.state.username}/>

                                    {/* 详情面板 */}
                                    {
                                        isHomePage() &&
                                        <TcCvUserHomePanelPhone
                                            wp={wp}
                                            width={wp.currWidth + "px"}
                                            height={(wp.currHeight - 48 - 67) + "px"}
                                            showMixVideoDetailCard={(d) => {
                                                this.showMixVideoDetailCard(d)
                                            }}
                                            showCharacterDetailCard={(d) => {
                                                this.showCharacterDetailCard(d)
                                            }}
                                            showTemplateDetailCard={(d) => {
                                                this.showTemplateDetailCard(d)
                                            }}
                                        />
                                    }

                                    <TcCvUserBottomNav wp={wp} height={"67px"} showGenerationPopup={() => {
                                        this.updateAndSaveState({
                                            generationPopupProps: {
                                                initCharacter: undefined,
                                                initTemplate: undefined
                                            }
                                        })
                                    }}/>
                                </ColumnUnit>
                            }

                            {
                                getDisplayMode(wp.currWidth) != "mobile" &&
                                <ColumnUnit sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
                                    {/* 主导航 */}
                                    <TcCvUserTopNavPc
                                        key={"TcCvUserTopNavPc-" + this.state.avatar + "-" + this.state.username}
                                        wp={wp} navHeight={"64px"}
                                        avatar={this.state.avatar}
                                        username={this.state.username}/>

                                    {/* 详情面板 */}
                                    <RowUnit sizeAttr={new SizeAttr(wp, "100vw", panelHeight)}>
                                        <TcCvUserLeftMenu
                                            showGenerationPopup={() => {
                                                this.updateAndSaveState({
                                                    generationPopupProps: {
                                                        initCharacter: undefined,
                                                        initTemplate: undefined
                                                    }
                                                })
                                            }}
                                            wp={wp} menuHeight={panelHeight}/>

                                        {
                                            isHomePage() &&
                                            <TcCvUserHomePanel wp={wp} width={panelWidth} height={panelHeight}
                                                               showMixVideoDetailCard={(d) => {
                                                                   this.showMixVideoDetailCard(d)
                                                               }}
                                                               showCharacterDetailCard={(d) => {
                                                                   this.showCharacterDetailCard(d)
                                                               }}
                                                               showTemplateDetailCard={(d) => {
                                                                   this.showTemplateDetailCard(d)
                                                               }}
                                            />
                                        }

                                        {
                                            window.location.href.indexOf("mine") >= 0 &&
                                            <TcCvUserMinePanel wp={wp} width={panelWidth} height={panelHeight}
                                                               showMixVideoDetailCard={(d) => {
                                                                   this.showMixVideoDetailCard(d)
                                                               }}
                                                               showCharacterDetailCard={(d) => {
                                                                   this.showCharacterDetailCard(d)
                                                               }}
                                                               showTemplateDetailCard={(d) => {
                                                                   this.showTemplateDetailCard(d)
                                                               }}
                                                               registerNewMixVideoCallback={(callback) => {
                                                                   this.updateAndSaveState({newMixVideoCallback: callback})
                                                               }}
                                            />
                                        }
                                    </RowUnit>
                                </ColumnUnit>
                            }
                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}