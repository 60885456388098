import {WindowParam} from "../../../library/context/WindowContext";
import {SizeAttr} from "../../../library/basic/size";
import {ColumnAttr, RowAttr} from "../../../library/basic/compose";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import backIcon from "../../../assets/prompt/page_switch_back_icon.svg";
import nextIcon from "../../../assets/prompt/page_switch_next_icon.svg";
import dotIcon from "../../../assets/prompt/page_switch_dot_icon.svg";
import currentPageIcon from "../../../assets/prompt/page_switch_current_bg.svg";
import otherPageIcon from "../../../assets/prompt/page_switch_other_bg.svg";
import {smallPadding} from "../display/layout";

function pageSwitcherEleArr(currentPage: number, totalPage: number): number[] {
    if (totalPage <= 5) {
        // 如果总页数小于等于5，直接返回所有页码
        return Array.from({length: totalPage}, (_, i) => i + 1);
    }

    const pages: (number | -1)[] = [];
    const range = 2; // 当前页前后各显示2页

    // 添加首页
    pages.push(1);

    // 添加当前页及其前后两页
    for (let i = Math.max(2, currentPage - range); i <= Math.min(totalPage - 1, currentPage + range); i++) {
        if (pages.length > 0 && pages[pages.length - 1] !== i - 1) {
            // 如果当前页码与上一个页码不连续，添加省略号
            pages.push(-1);
        }
        pages.push(i);
    }

    // 添加尾页
    if (pages[pages.length - 1] !== totalPage) {
        if (pages[pages.length - 1] !== totalPage - 1) {
            // 如果尾页与最后一个页码不连续，添加省略号
            pages.push(-1);
        }
        pages.push(totalPage);
    }

    return pages;
}

export function PageSwitcherPc(wp: WindowParam, currentPage: number, totalPage: number,
                               triggerPageSwitch: (newPage: number) => void) {
    return (
        <RowUnit
            sizeAttr={new SizeAttr(wp, "", "32px")}
            customStyleAttr={ColumnAttr.getChildrenPositionObj()}>

            <img
                onClick={() => {
                    if (currentPage > 1) {
                        triggerPageSwitch(currentPage - 1)
                    }
                }}
                className={"hoverCursor"}
                style={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "width": "24px",
                    "height": "24px",
                    "marginRight": "16px"
                })}
                alt={"back"}
                src={backIcon}/>

            {
                pageSwitcherEleArr(currentPage, totalPage).map((v, idx) => {
                    if (v > 0) {
                        return (
                            <div
                                onClick={() => {
                                    if (currentPage != v) {
                                        triggerPageSwitch(v)
                                    }
                                }}
                                className={"hoverCursor"}
                                style={{
                                    "display": "flex",
                                    "width": "32px",
                                    "height": "32px",
                                    "marginRight": idx == pageSwitcherEleArr(currentPage, totalPage).length - 1 ? "" : smallPadding,
                                    "backgroundImage": `url(${v == currentPage ? currentPageIcon : otherPageIcon})`,
                                }}>
                                <span style={{
                                    "margin": "auto",
                                    "fontSize": "18px",
                                    "fontWeight": "600",
                                    "transform": "translateY(2px)",
                                    "color": v == currentPage ? "#c9c9c9" : "#959595"
                                }}>
                                    {v + ""}
                                </span>
                            </div>
                        )
                    } else {
                        return (
                            <img
                                style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "width": "19px",
                                    "height": "19px",
                                    "marginRight": smallPadding
                                })}
                                alt={"dot"}
                                src={dotIcon}/>
                        )
                    }
                })
            }

            <img
                onClick={() => {
                    if (currentPage < totalPage) {
                        triggerPageSwitch(currentPage + 1)
                    }
                }}
                className={"hoverCursor"}
                style={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "width": "24px",
                    "height": "24px",
                    "marginLeft": "16px"
                })}
                alt={"next"}
                src={nextIcon}/>

        </RowUnit>
    )
}