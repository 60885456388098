// 页面布局属性
import {WindowParam} from "../../../library/context/WindowContext";

export const largePadding = "32px";
export const mediumPadding = "16px";
export const smallPadding = "8px"

export const largeRadius = "32px";
export const mediumRadius = "16px";
export const smallRadius = "8px"

export type DisplayMode = "pc" | "pad" | "mobile"

export function getDisplayMode(width: number): DisplayMode {
    if (width > 1024) return "pc"
    if (width > 600) return "pad"
    return "mobile"
}

export function popUpBaseUnit(wp: WindowParam): number {
    let bw = wp.currWidth / 1512
    let bh = wp.currHeight / 982

    if (wp.currHeight < 1000) {
        bw = wp.currWidth / 1180
        bh = wp.currHeight / 801
        return Math.min(bw, bh) * 0.88
    }

    console.log("popUpBaseUnit", wp.currHeight)
    return Math.min(bw, bh)
}

export function popUpWidthNum(wp: WindowParam): number {
    return popUpBaseUnit(wp) * 1180
}

export function popUpHeightNum(wp: WindowParam): number {
    return popUpBaseUnit(wp) * 801
}

export function guideWidthNum(wp: WindowParam): number {
    return popUpBaseUnit(wp) * 751
}
