/** @format */
import {Gltf, OrbitControls} from '@react-three/drei'
import {Canvas, useFrame, useLoader, useThree, Vector3} from '@react-three/fiber'
import * as React from 'react'
import {FC, Suspense, useEffect, useRef, useState} from 'react'
import {AnimationMixer, Clock, EquirectangularReflectionMapping, PMREMGenerator, TextureLoader} from 'three';
import {GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {LoadingOutlined} from "@ant-design/icons";
import {BoxAttr} from "../../../library/basic/compose";
import { AnimalSimplePlayer } from './AnimalSimplePlayer';
export const GLBPlayer=AnimalSimplePlayer

const CustomEnvironment: React.FC<{ path: string }> = ({path}) => {
    const {gl, scene} = useThree();
    const pmremGenerator = useRef(new PMREMGenerator(gl));

    useEffect(() => {
        const textureLoader = new TextureLoader();
        textureLoader.load(path, (hdrTexture) => {
            hdrTexture.mapping = EquirectangularReflectionMapping;

            const envMap = pmremGenerator.current.fromEquirectangular(hdrTexture).texture;

            scene.environment = envMap;
            scene.background = envMap;

            // Dispose of the original HDR texture and the PMREM generator
            hdrTexture.dispose();
            pmremGenerator.current.dispose();
        });

        // Clean up
        return () => {
            pmremGenerator.current.dispose();
        };
    }, [path, gl, scene, pmremGenerator]);

    return null;
};

interface IProps {
    src: string
    width: string
    height: string
    disableOperation?: boolean
}

export const GLBPlayer1: FC<IProps> = ({src, width, height, disableOperation}: IProps) => {
    let position: Vector3 = [0, 0, 10]
    let scale = 3
    const [loadState, setLoadState] = useState("loading");
    const [glb, setGlb] = useState<undefined | GLTF>(undefined)

    const mixerRef = useRef<AnimationMixer | null>(null);

    useEffect(() => {
        console.log("GlbPlayer UseEffect")
        const loader = new GLTFLoader();
        loader.load(
            src,
            (gltf) => {
                console.log("GlbPlayer load success", gltf)
                setGlb(gltf)
                if (gltf.animations && gltf.animations.length > 0) {
                    mixerRef.current = new AnimationMixer(gltf.scene);
                    const action = mixerRef.current.clipAction(gltf.animations[0]);
                    action.play();
                    console.log("GlbPlayer action play", action)
                }
                setLoadState("success")
            },
            undefined,
            (error) => {
                console.error('An error happened', error);
                setLoadState("error")
            }
        );

        // 返回清理函数
        return () => {
            if (mixerRef.current) {
                mixerRef.current.stopAllAction();
            }
        };
    }, []);

    if (loadState == "success" && glb != undefined) {
        return (
            <GLBInnerPlayer
                glb={glb}
                width={width}
                height={height}
                mixerRef={mixerRef}
                enableOperation={!disableOperation}
            />
        )
    } else if (loadState == "loading") {
        return <LoadingOutlined style={BoxAttr.getChildrenPositionObj()}/>
    } else {
        return <div></div>
    }
}

interface InnerIProps {
    glb: GLTF
    width: string
    height: string
    mixerRef: React.MutableRefObject<AnimationMixer | null>
    enableOperation: boolean
}

const GLBInnerPlayer: FC<InnerIProps> = ({glb, width, height, mixerRef, enableOperation}: InnerIProps) => {
    let position: Vector3 = [0, 0, 10]
    let scale = 3

    const [isPlaying, setIsPlaying] = useState(true);

    const AnimationHandler = () => {
        useFrame((state, delta) => {
            if (mixerRef.current && isPlaying) {
                const time = mixerRef.current.update(delta);
                const clip = mixerRef.current.clipAction(glb.animations[0]);
                console.log("GLBInnerPlayer mixerRef update", time.time, clip.time, clip.getClip().duration)
                if (clip && time.time >= clip.getClip().duration) {
                    setIsPlaying(false);
                    console.log("Animation finished");
                }
            }
        });

        return null; // 这个组件只用于处理动画更新，不需要渲染任何内容
    };

    useEffect(() => {
        if (glb && glb.animations.length > 0) {
            const mixer = new AnimationMixer(glb.scene);
            mixerRef.current = mixer;
            const clip = mixer.clipAction(glb.animations[0]);
            clip.play();
        }
    }, [glb, mixerRef]);

    return (
        <Canvas style={{width: width, height: height}}
                frameloop='always' orthographic={true} camera={{zoom: 60, position: [0, 0, 100]}}>
            <ambientLight intensity={2.5}/>
            <directionalLight intensity={1}/>
            <OrbitControls target={[0, 3.5, 10]}
                           enableZoom={enableOperation}
                           enableDamping={enableOperation}
                           enablePan={enableOperation}
                           enableRotate={enableOperation} />

            <mesh>
                <Suspense>
                    {/*<Model src={src} position={position} scale={scale}/>*/}
                    <group position={position} scale={scale}>
                        <primitive object={glb.scene}/>
                    </group>
                </Suspense>
            </mesh>

            <CustomEnvironment path="/prompt/sunset.hdr"/>

            {/* 将 AnimationHandler 放在 Canvas 内部 */}
            <AnimationHandler />

        </Canvas>
    )
}