import queryString from "query-string";
import {NavigateFunction, useNavigate} from "react-router";

export function toLink(link: string | undefined, navigate: NavigateFunction | undefined = undefined) {
    if (link) {
        if (navigate && link.startsWith("/")) {
            navigate(link)
            return
        }
        window.location.href = link;
    }
}

export const homePath = "/"

export function isHomePage() {
    return window.location.href.indexOf("home") >= 0 || window.location.pathname == "" || window.location.pathname == "/"
}

export function fetchStrParam(key: string): string {
    let params = queryString.parse(window.location.search)
    let res = params[key];
    if (res) {
        if (res instanceof Array) {
            res = res[0]
        }
        return res as string
    } else {
        return ""
    }
}

export function fetchNumberParam(key: string): number {
    let params = queryString.parse(window.location.search)
    let res = params[key];
    if (res) {
        if (res instanceof Array) {
            res = res[0]
        }
        try {
            return Number(res)
        } catch (e) {
            return 0
        }
    } else {
        return 0
    }
}