import {TC_AIUNI_CV_BACKEND_URL} from "../../../library/util/apiUtil";
import {TcCvTemplatesPaginationData, TcCvTemplatesPaginationResp} from "./TcCvTemplates";
import {accountLogout, getToken, getUserInfo, saveToken, saveUserInfo} from "../util/account";

export type UserDb = {
    id: string
    nickname: string
    avatar: string
}

export function emptyUserDb(): UserDb {
    return {
        id: "",
        nickname: "",
        avatar: ""
    }
}

export type User = {
    c: number // status
    exp: number // expired
    sub: string
    email: string
    email_verified: boolean
    phone_number: string
    phone_number_verified: boolean
    picture: string
    nickname: string
}

export type UserInfo = {
    token: string
    creation_count: number
    character_count: number
    template_count: number
    user: User
}

export type QueryUserInfoReq = {}

export type QueryUserInfoResp = {
    code: number
    msg: string
    data: UserInfo
}

export function queryUserInfo(req: QueryUserInfoReq, callback: (success: boolean, resp?: QueryUserInfoResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryUserInfo", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as QueryUserInfoResp;
                if (!getToken()) {
                    saveToken(res.data.token)
                }
                if (resp.code == 200) {
                    saveUserInfo(res.data)
                } else {
                    accountLogout()
                }
                callback(resp.code === 200, resp);
            } catch (e) {
                accountLogout()
                callback(false, undefined);
            }
        })
}