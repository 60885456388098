export const TOS_TEMP_HOST = "https://aiuni.cdn.avar.cn"
export const TOS_TEMP_HOST_PATH = TOS_TEMP_HOST + "/"

export function tosLink(key: string): string {
    if (key.startsWith("http")) {
        return key
    }
    if (key == "") {
        return ""
    }
    return TOS_TEMP_HOST_PATH + key
}

export const HostUrl = 'https://api.aiuni.ai/2'
export const UploaderTransportUrl = HostUrl + '/tos/uploader/upload'
export const UploaderMergeUrl = HostUrl + '/tos/uploader/merge'
export const UploaderTouchUrl = HostUrl + '/tos/uploader/init'
