// src/database.ts
import { openDB, IDBPDatabase } from 'idb';

const VERSION = 1;

export type MyData = {
    id?: number;
    value: string;
}

export const initDatabase = async (DB_NAME: string, STORE_NAME: string): Promise<IDBPDatabase<MyData>> => {
    const db = await openDB<MyData>(DB_NAME, VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
            }
        },
    });
    return db;
};

export const addData = async (db: IDBPDatabase<MyData>, STORE_NAME: string, data: MyData): Promise<void> => {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.add(data);
    await tx.done;
};

export const getData = async (db: IDBPDatabase<MyData>, STORE_NAME: string, id: number): Promise<MyData | undefined> => {
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    try {
        const data = await store.get(id);
        await tx.done;
        return data
    } catch (e) {
        return undefined
    }
};

export const getAllData = async (db: IDBPDatabase<MyData>, STORE_NAME: string): Promise<MyData[]> => {
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const data = await store.getAll();
    await tx.done;
    return data;
};

export const updateData = async (db: IDBPDatabase<MyData>, STORE_NAME: string, id: number, data: MyData): Promise<void> => {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.put({ ...data, id });
    await tx.done;
};

export const deleteData = async (db: IDBPDatabase<MyData>, STORE_NAME: string, id: number): Promise<void> => {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.delete(id);
    await tx.done;
};