import {Component} from "react";
import {WindowParamContext} from "../../../library/context/WindowContext";
import {LabPanelPcAndPad} from "./LabPanelPcAndPad";
import "./LabPanel.css"
import {getDisplayMode} from "../display/layout";
import {LabPanelPhone} from "./LabPanelPhone";
import {LabIndex} from "../TcCvPromptPage";

export interface LabCardData {
    id: string
}

export type LabPanelProps = {
    panelName: LabIndex
    width: string
    height: string
    categoryList: string[]
    showCopyPanel: () => void
    updateDetailData: (d: LabCardData) => void
}


export class LabPanel extends Component<LabPanelProps> {
    static contextType = WindowParamContext;

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;

                    if (getDisplayMode(wp.currWidth) == "mobile") {
                        return <LabPanelPhone {...this.props}/>
                    }
                    return <LabPanelPcAndPad {...this.props}/>
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}
