import {WindowParam} from "../../../library/context/WindowContext";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import {SizeAttr} from "../../../library/basic/size";
import {ImageUnit} from "../../../library/atomic/image/ImageUnit";
import searchBoxIcon from "../../../assets/prompt/search_box_icon.svg";
import {RowAttr} from "../../../library/basic/compose";
import {mediumPadding} from "../display/layout";

export function SearchBox(wp: WindowParam, width: string,
                          value: string, setValue: (v: string) => void,
                          triggerSearch: () => void, height: string = "30px", fontSize: string = "16px") {
    let inputWidth = (SizeAttr.getPxNumber(width) - 32 - 22 - 8) + "px"

    return (
        <RowUnit
            customStyleAttr={{
                "boxSizing": "borderBox",
                "borderRadius": SizeAttr.pxDivide(height, 2),
                "border": "1px solid #5B5B5B",
                "background": "#18181B"
            }}
            sizeAttr={new SizeAttr(wp, width, height)}
        >
            <input
                value={value}
                onChange={(event) => {
                    setValue(event.target.value)
                }}
                placeholder={""}
                style={{
                    "marginTop": "auto",
                    "marginBottom": "auto",
                    "width": inputWidth,
                    "fontSize": fontSize,
                    "color": "#ffffff",
                    "marginLeft": mediumPadding,
                    "background": "transparent",
                    "border": "none"
                }}/>

            <ImageUnit
                needHover={true}
                onClick={() => {
                    triggerSearch()
                }}
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginRight": mediumPadding,
                    "marginLeft": "8px"
                })}
                innerImgStyle={{
                    "width": SizeAttr.pxMultiple(height, 22 / 30),
                    "height": SizeAttr.pxMultiple(height, 18 / 30)
                }}
                sizeAttr={new SizeAttr(wp, SizeAttr.pxMultiple(height, 22 / 30), SizeAttr.pxMultiple(height, 18 / 30))}
                url={searchBoxIcon}/>

        </RowUnit>
    )
}