import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC, useEffect, useState} from "react";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {mediumPadding, mediumRadius} from "../../display/layout";
import {SizeAttr} from "../../../../library/basic/size";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {ColorAttr} from "../../../../library/basic/color";
import {FontAttr} from "../../../../library/basic/font";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import userDefaultAvatar from "../../../../assets/user/nav/user_default_avatar.svg";
import "./TcCvUserMinePanel.css"
import {SpanHoverText} from "../../../../library/atomic/text/SpanHoverText";
import {queryUserCvMixVideosByPage, TcCvMixVideosData} from "../../data/TcCvMixVideos";
import {queryUserCvCharactersByPage, TcCvCharactersData} from "../../data/TcCvCharacters";
import {queryUserCvTemplatesByPage, TcCvTemplatesData} from "../../data/TcCvTemplates";
import {CardScrollingPanel} from "../popup/CardSelectPopup";
import {LabIndex} from "../../TcCvPromptPage";
import {v4 as uuidv4} from "uuid";

interface TcCvUserMinePanelProps {
    wp: WindowParam
    width: string
    height: string
    showMixVideoDetailCard: (d: TcCvMixVideosData) => void
    showCharacterDetailCard: (d: TcCvCharactersData) => void
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
    registerNewMixVideoCallback: (callback: () => void) => void
}

export const TcCvUserMinePanel: FC<TcCvUserMinePanelProps> = ({
                                                                  wp,
                                                                  width,
                                                                  height,
                                                                  showMixVideoDetailCard,
                                                                  showCharacterDetailCard,
                                                                  showTemplateDetailCard,
                                                                  registerNewMixVideoCallback
                                                              }: TcCvUserMinePanelProps) => {
    // 当前选择的选项卡
    const [currentUserLabIndex, setCurrentUserLabIndex] = useState<LabIndex>("mixVideo");

    // mixVideo选项卡uuid
    const [mixVideoUuidKey, setMixVideoUuidKey] = useState<string>(uuidv4())

    useEffect(() => {
        registerNewMixVideoCallback(() => {
            setMixVideoUuidKey(uuidv4())
        })
    }, []);


    let widthNum = SizeAttr.getPxNumber(width);
    let heightNum = SizeAttr.getPxNumber(height)

    let innerColWidthNum = widthNum - 32 * 2
    let userBarHeightNum = 59 + 59 + 14;

    let userBarBottomMargin = 36;
    let totalTopHeight = userBarHeightNum + (userBarBottomMargin + 16 + 40);

    let scrollHeight = heightNum - totalTopHeight + userBarHeightNum - userBarBottomMargin;

    // 计算单行卡片个数和卡片宽度
    let cardGap = 16
    let lineCount = 5
    let cardWidthNum = (innerColWidthNum - cardGap * (lineCount - 1)) / lineCount

    // 数据搜索API
    let queryUserCvMixVideosDataQueryApi: (req: {
        page_num: number,
        page_size: number
    }, callback: (success: boolean, resp: any) => void) => void = (req, callback) => {
        queryUserCvMixVideosByPage({
            page_num: req.page_num,
            page_size: req.page_size
        }, callback)
    }
    let queryUserCvCharactersDataQueryApi: (req: {
        page_num: number,
        page_size: number
    }, callback: (success: boolean, resp: any) => void) => void = (req, callback) => {
        queryUserCvCharactersByPage({
            page_num: req.page_num,
            page_size: req.page_size
        }, callback)
    }
    let queryUserCvTemplatesDataQueryApi: (req: {
        page_num: number,
        page_size: number
    }, callback: (success: boolean, resp: any) => void) => void = (req, callback) => {
        queryUserCvTemplatesByPage({
            page_num: req.page_num,
            page_size: req.page_size
        }, callback)
    }

    return (
        <ColumnUnit sizeAttr={new SizeAttr(wp, width, height)}>
            <ColumnUnit
                customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, innerColWidthNum + "px", height)}>

                {/*选择栏*/}
                <RowUnit
                    customStyleAttr={{
                        "marginTop": userBarBottomMargin + "px",
                        "marginBottom": mediumPadding
                    }}
                    sizeAttr={new SizeAttr(wp, innerColWidthNum + "px", "40px")}
                >
                    <LabIndexItem
                        wp={wp}
                        text={"My Generations"}
                        selected={currentUserLabIndex == "mixVideo"}
                        lineWidth={"134px"}
                        onClick={() => {
                            if (currentUserLabIndex != "mixVideo") {
                                setCurrentUserLabIndex("mixVideo")
                            }
                        }}
                    />
                    <LabIndexItem
                        wp={wp}
                        text={"My Characters"}
                        selected={currentUserLabIndex == "character"}
                        lineWidth={"124px"}
                        onClick={() => {
                            if (currentUserLabIndex != "character") {
                                setCurrentUserLabIndex("character")
                            }
                        }}
                    />
                    <LabIndexItem
                        wp={wp}
                        text={"My Templates"}
                        selected={currentUserLabIndex == "template"}
                        lineWidth={"120px"}
                        onClick={() => {
                            if (currentUserLabIndex != "template") {
                                setCurrentUserLabIndex("template")
                            }
                        }}
                    />
                </RowUnit>

                {/*分页面板*/}
                {
                    currentUserLabIndex == "mixVideo" &&
                    <CardScrollingPanel
                        key={"TcCvUserMinePanel-CardScrollingPanel" + currentUserLabIndex + "-" + mixVideoUuidKey}
                        labIndex={currentUserLabIndex}
                        width={innerColWidthNum + "px"}
                        height={scrollHeight + "px"}
                        cardWidth={cardWidthNum + "px"}
                        cardGap={cardGap + "px"}
                        lineCount={lineCount}
                        showDetailCard={(d) => {
                            showMixVideoDetailCard(d)
                        }}
                        dataQueryApi={queryUserCvMixVideosDataQueryApi}
                    />
                }
                {
                    currentUserLabIndex == "character" &&
                    <CardScrollingPanel
                        key={"TcCvUserMinePanel-CardScrollingPanel" + currentUserLabIndex}
                        labIndex={currentUserLabIndex}
                        width={innerColWidthNum + "px"}
                        height={scrollHeight + "px"}
                        cardWidth={cardWidthNum + "px"}
                        cardGap={cardGap + "px"}
                        lineCount={lineCount}
                        showDetailCard={(d) => {
                            showCharacterDetailCard(d)
                        }}
                        dataQueryApi={queryUserCvCharactersDataQueryApi}
                    />
                }
                {
                    currentUserLabIndex == "template" &&
                    <CardScrollingPanel
                        key={"TcCvUserMinePanel-CardScrollingPanel" + currentUserLabIndex}
                        labIndex={currentUserLabIndex}
                        width={innerColWidthNum + "px"}
                        height={scrollHeight + "px"}
                        cardWidth={cardWidthNum + "px"}
                        cardGap={cardGap + "px"}
                        lineCount={lineCount}
                        showDetailCard={(d) => {
                            showTemplateDetailCard(d)
                        }}
                        dataQueryApi={queryUserCvTemplatesDataQueryApi}
                    />
                }
            </ColumnUnit>
        </ColumnUnit>
    )
}

interface MinePanelButtonProps {
    wp: WindowParam
    icon: string
    text: string
    marginBottom: string
    textColor: string
    useLine: boolean
    onClick: () => void
}

const MinePanelButton: FC<MinePanelButtonProps> = ({
                                                       wp,
                                                       icon,
                                                       text,
                                                       marginBottom,
                                                       textColor,
                                                       useLine,
                                                       onClick
                                                   }: MinePanelButtonProps) => {
    return (
        <BoxUnit
            onClick={() => {
                onClick()
            }}
            customStyleAttr={{
                "borderRadius": mediumRadius,
                "marginBottom": marginBottom,
                "position": "relative"
            }}
            colorAttr={new ColorAttr(wp, "", "#252525")}
            classname={"tc-cv-user-mine-panel-button"}
            sizeAttr={new SizeAttr(wp, "298px", "59px")}>

            {
                useLine && <BoxUnit
                    sizeAttr={new SizeAttr(wp, "298px", "4px")}
                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                        "background": "linear-gradient(to right, #4DD1C19A 0%, #4F78C89A 26%, #8F51B89A 51%, #BE57819A 76%, #BF73539A 100%)",
                        "borderRadius": "2px",
                        "position": "absolute",
                        "bottom": "0"
                    })}
                />
            }

            <RowUnit
                customStyleAttr={BoxAttr.getChildrenPositionObj()}
            >
                <ImageUnit
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, "35px", "35px")}
                    url={icon}/>
                <BoxUnit
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, "180px")}>
                    <SpanText
                        customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                            "transform": "translateY(2px)",
                            "letterSpacing": "2px"
                        })}
                        colorAttr={new ColorAttr(wp, textColor)}
                        fontAttr={new FontAttr(wp, "26px", "500")}
                    >
                        {text}
                    </SpanText>
                </BoxUnit>
            </RowUnit>


        </BoxUnit>
    )
}

interface MineUserInfoBarProps {
    wp: WindowParam
    width: string
    height: string
    avatar: string
    username: string
    membershipInfo: string
    creationCount: number
    characterCount: number
    templateCount: number
}

const MineUserInfoBar: FC<MineUserInfoBarProps> = ({
                                                       wp,
                                                       width,
                                                       height,
                                                       avatar,
                                                       username,
                                                       membershipInfo,
                                                       creationCount,
                                                       characterCount,
                                                       templateCount
                                                   }: MineUserInfoBarProps) => {
    let countBlock = (c: number, t: string) => {
        return (
            <ColumnUnit
                sizeAttr={new SizeAttr(wp, "100px", "57px")}
            >
                <SpanText
                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                        "marginBottom": "auto"
                    })}
                    colorAttr={new ColorAttr(wp, "#ffffff")}
                    fontAttr={new FontAttr(wp, "28px", "600")}
                >
                    {c + ""}
                </SpanText>
                <SpanText
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    colorAttr={new ColorAttr(wp, "#7A7A7A")}
                    fontAttr={new FontAttr(wp, "18px", "600")}
                >
                    {t}
                </SpanText>
            </ColumnUnit>
        )
    }


    return (
        <RowUnit
            colorAttr={new ColorAttr(wp, "", "#1a1a1a")}
            customStyleAttr={{
                "borderRadius": mediumRadius
            }}
            sizeAttr={new SizeAttr(wp, width, height)}>

            <ImageUnit
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginLeft": "24px",
                    "marginRight": "26px"
                })}
                sizeAttr={new SizeAttr(wp, "80px", "80px")}
                innerImgStyle={{
                    "borderRadius": "40px"
                }}
                url={avatar ? avatar : userDefaultAvatar}/>

            <ColumnUnit
                sizeAttr={new SizeAttr(wp, "", "80px")}
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginRight": "auto"
                })}
            >
                <SpanText
                    customStyleAttr={{
                        "justifyContent": "",
                        "marginBottom": "auto"
                    }}
                    colorAttr={new ColorAttr(wp, "#ffffff")}
                    fontAttr={new FontAttr(wp, "22px", "600")}
                >
                    {username ? username : "User Name"}
                </SpanText>
                <SpanText
                    customStyleAttr={{
                        "justifyContent": ""
                    }}
                    colorAttr={new ColorAttr(wp, "#777777")}
                    fontAttr={new FontAttr(wp, "22px", "500")}
                >
                    {membershipInfo}
                </SpanText>
            </ColumnUnit>

            <RowUnit
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginRight": "26px",
                    "transform": "translateY(-6px)",
                })}
            >
                {countBlock(creationCount, "Creation")}
                {countBlock(characterCount, "Character")}
                {countBlock(templateCount, "Template")}
            </RowUnit>

        </RowUnit>
    )
}


interface LabIndexItemProps {
    wp: WindowParam
    text: string
    selected: boolean
    lineWidth: string,
    onClick: () => void
}

const LabIndexItem: FC<LabIndexItemProps> = ({
                                                 wp,
                                                 text,
                                                 selected,
                                                 lineWidth,
                                                 onClick
                                             }: LabIndexItemProps) => {
    return (
        <RowUnit
            key={"LabIndexItem-" + text + "-" + selected}
            sizeAttr={new SizeAttr(wp, "172px", "40px")}>
            <ColumnUnit
                customStyleAttr={RowAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, "40px")}>
                <SpanHoverText
                    onClick={() => {
                        onClick()
                    }}
                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                        "whiteSpace": "nowrap"
                    })}
                    colorAttr={new ColorAttr(wp, selected ? "#ffffff" : "#888888")}
                    fontAttr={new FontAttr(wp, "22px", "600")}
                >
                    {text}
                </SpanHoverText>
                <BoxUnit
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    classname={`tc-cv-user-mine-panel-index-item-line ${selected ? 'selected' : ''}`}
                    sizeAttr={new SizeAttr(wp, lineWidth, "6px")}/>
            </ColumnUnit>
        </RowUnit>
    )
}