import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC, useState} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {ColorAttr} from "../../../../library/basic/color";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {TcCvMixVideosData} from "../../data/TcCvMixVideos";
import {mediumPadding, mediumRadius, popUpBaseUnit, popUpHeightNum, popUpWidthNum} from "../../display/layout";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import mixPopupCloseIcon from "../../../../assets/user/popup/mix/mix_pop_up_close_icon.svg";
import exportVipIcon from "../../../../assets/user/popup/mix/export_vip_icon.svg";
import exportMoreIcon from "../../../../assets/user/popup/mix/export_more_icon.svg";
import mixRegenIcon from "../../../../assets/user/popup/mix/mix_regen_icon.svg";
import mixShareIcon from "../../../../assets/user/popup/mix/mix_share_icon.svg";
import mixDownloadIcon from "../../../../assets/user/popup/mix/detail_download_icon.svg";
import waterMarkOnIcon from "../../../../assets/user/popup/mix/watermark_on_icon.svg";
import {VideoSwitcher, VideoType} from "../VideoSwitcher";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {tosLink} from "../../../../library/util/tosUtil";
import creatorDefaultAvatar from "../../../../assets/user/popup/mix/creator_default_avatar.svg";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import {GenerationPopupInitProps} from "../../TcCvMainPage";
import {GLBPlayer} from "../GlbPlayer";
import {TcCvCharactersData} from "../../data/TcCvCharacters";
import {TcCvTemplatesData} from "../../data/TcCvTemplates";
import {mix} from "framer-motion";
import {SpanHoverText} from "../../../../library/atomic/text/SpanHoverText";

interface MixVideoPopupProps {
    wp: WindowParam
    mixVideo: TcCvMixVideosData
    closeClick: () => void
    showCharacterDetailCard: (d: TcCvCharactersData) => void
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
    showGenerationPopup: (d: GenerationPopupInitProps) => void
}

export const MixVideoPopup: FC<MixVideoPopupProps> = ({
                                                          wp,
                                                          mixVideo,
                                                          closeClick,
                                                          showCharacterDetailCard,
                                                          showTemplateDetailCard,
                                                          showGenerationPopup
                                                      }: MixVideoPopupProps) => {
    let bUnit = popUpBaseUnit(wp)
    let pw = popUpWidthNum(wp)
    let ph = popUpHeightNum(wp)

    let leftPw = 590 * bUnit
    let rightPw = pw - leftPw
    let rightInnerPw = rightPw - 32

    // 右侧面板数据
    let rightMiddleColPw = (rightInnerPw - 22) / 2
    let avatarSize = 36 * bUnit
    let creatorNameFontSize = 18 * bUnit
    let rightMiddleImgSize = 80 * bUnit
    let rightMiddleHintFontSize = 16 * bUnit

    let rightMiddleButtonPw = (rightInnerPw - 8) / 2

    let rightBottomIconSize = 36 * bUnit

    return (
        <BoxUnit
            stopClickPropagation={true}
            colorAttr={new ColorAttr(wp, "", "#161616")}
            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                "borderRadius": "20px",
                "border": "2px solid #3A3A3A",
                "position": "relative",
                "overflow": "hidden"
            })}
            sizeAttr={new SizeAttr(wp, pw + "px", ph + "px")}>

            <ImageUnit
                onClick={() => {
                    closeClick()
                }}
                customStyleAttr={{
                    "position": "absolute",
                    "top": 16 + "px",
                    "right": 16 + "px"
                }}
                sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (24 * bUnit) + "px")}
                url={mixPopupCloseIcon}/>

            <ColumnUnit
                customStyleAttr={{
                    "background": "#000000"
                }}
                sizeAttr={new SizeAttr(wp, leftPw + "px", ph + "px")}>
                <BoxUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                    <VideoSwitcher
                        key={"VideoSwitcher-mix-video-popup-" + mixVideo.id}
                        wp={wp}
                        w={leftPw}
                        h={ph}
                        switcherOffset={mediumRadius}
                        mixVideo={mixVideo.display_video}
                        inpaintVideo={mixVideo.template.inpaint_video}
                        oriVideo={mixVideo.template.org_video}
                        switcherType={"mix"}
                    />
                </BoxUnit>
            </ColumnUnit>

            <ColumnUnit
                customStyleAttr={{
                    "background": "#101010"
                }}
                sizeAttr={new SizeAttr(wp, rightPw + "px", ph + "px")}>
                <ColumnUnit
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, rightInnerPw + "px", ph + "px")}>

                    <RowUnit
                        customStyleAttr={{
                            "marginTop": mediumPadding,
                            "marginBottom": mediumPadding,
                            "marginRight": "auto"
                        }}
                    >
                        <ImageUnit
                            sizeAttr={new SizeAttr(wp, avatarSize + "px", avatarSize + "px")}
                            customStyleAttr={{
                                "borderRadius": (avatarSize / 2) + "px",
                                "marginRight": (13 * bUnit) + "px",
                                "overflow": "hidden"
                            }}
                            url={mixVideo.user.avatar ? mixVideo.user.avatar : creatorDefaultAvatar}/>
                        <SpanText
                            customStyleAttr={RowAttr.getChildrenPositionObj()}
                            colorAttr={new ColorAttr(wp, "#ffffff")}
                            fontAttr={new FontAttr(wp, creatorNameFontSize + "px", "600")}>
                            {mixVideo.user.nickname}
                        </SpanText>
                    </RowUnit>

                    <BoxUnit
                        colorAttr={new ColorAttr(wp, "", "#2E2E2E")}
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px", "1px")}/>

                    <RowUnit
                        colorAttr={new ColorAttr(wp, "#6d6d6d")}
                        fontAttr={new FontAttr(wp, rightMiddleHintFontSize + "px", "500")}
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px")}
                        customStyleAttr={{
                            "marginTop": mediumPadding,
                            "marginBottom": mediumPadding
                        }}
                    >
                        <ColumnUnit
                            customStyleAttr={{"marginRight": "auto"}}
                            sizeAttr={new SizeAttr(wp, rightMiddleColPw + "px")}>
                            <RowUnit>
                                <SpanText customStyleAttr={{"marginRight": "auto"}}>{"Character"}</SpanText>
                                <SpanHoverText
                                    onClick={() => {
                                        showCharacterDetailCard(mixVideo.character)
                                        closeClick()
                                    }}
                                    colorAttr={new ColorAttr(wp, "#C3C3C3")}>
                                    {"@" + mixVideo.character.name}
                                </SpanHoverText>
                            </RowUnit>
                            <BoxUnit
                                needHover={true}
                                onClick={() => {
                                    showCharacterDetailCard(mixVideo.character)
                                    closeClick()
                                }}
                                sizeAttr={new SizeAttr(wp, rightMiddleImgSize + "px",
                                    rightMiddleImgSize + "px")}
                                customStyleAttr={{
                                    "background": "#000000",
                                    "marginTop": (8 * bUnit) + "px",
                                    "marginBottom": (14 * bUnit) + "px",
                                    "marginLeft": "auto",
                                    "borderRadius": "8px"
                                }}>
                                <img
                                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "maxWidth": (0.8 * rightMiddleImgSize) + "px",
                                        "maxHeight": rightMiddleImgSize + "px"
                                    })}
                                    alt={""}
                                    src={tosLink(mixVideo.character.cover)}/>
                            </BoxUnit>
                            <RowUnit>
                                <SpanText customStyleAttr={{"marginRight": "auto"}}>{"Background"}</SpanText>
                                <SpanText colorAttr={new ColorAttr(wp, "#ffffff")}>{"Original"}</SpanText>
                            </RowUnit>
                        </ColumnUnit>
                        <ColumnUnit
                            sizeAttr={new SizeAttr(wp, rightMiddleColPw + "px")}>
                            <RowUnit>
                                <SpanText customStyleAttr={{"marginRight": "auto"}}>{"Template"}</SpanText>
                                <SpanHoverText
                                    onClick={() => {
                                        showTemplateDetailCard(mixVideo.template)
                                        closeClick()
                                    }}
                                    colorAttr={new ColorAttr(wp, "#C3C3C3")}>
                                    {"@" + mixVideo.template.name}
                                </SpanHoverText>
                            </RowUnit>
                            <BoxUnit
                                needHover={true}
                                onClick={() => {
                                    showTemplateDetailCard(mixVideo.template)
                                    closeClick()
                                }}
                                sizeAttr={new SizeAttr(wp, rightMiddleImgSize + "px",
                                    rightMiddleImgSize + "px")}
                                customStyleAttr={{
                                    "background": "#000000",
                                    "marginTop": (8 * bUnit) + "px",
                                    "marginBottom": (14 * bUnit) + "px",
                                    "marginLeft": "auto",
                                    "borderRadius": "8px"
                                }}>
                                <img
                                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "maxWidth": (0.8 * rightMiddleImgSize) + "px",
                                        "maxHeight": rightMiddleImgSize + "px"
                                    })}
                                    alt={""}
                                    src={tosLink(mixVideo.template.cover)}/>
                            </BoxUnit>
                            <RowUnit>
                                <SpanText customStyleAttr={{"marginRight": "auto"}}>{"Style"}</SpanText>
                                <SpanText colorAttr={new ColorAttr(wp, "#ffffff")}>{"Classic"}</SpanText>
                            </RowUnit>
                        </ColumnUnit>
                    </RowUnit>

                    <BoxUnit
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px", (364 * bUnit) + "px")}
                        colorAttr={new ColorAttr(wp, "", "#000000")}
                    >
                        <BoxUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                            <GLBPlayer
                                key={"MixVideo-Popup-GLBPlayer-" + mixVideo.id}
                                src={tosLink(mixVideo.character.rig_model)}
                                width={(rightInnerPw - 64) + "px"}
                                height={(364 * bUnit) + "px"}
                            />
                        </BoxUnit>
                    </BoxUnit>

                    <RowUnit
                        colorAttr={new ColorAttr(wp, "#AAAAAA")}
                        fontAttr={new FontAttr(wp, rightMiddleHintFontSize + "px", "500")}
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px")}
                        customStyleAttr={{
                            "marginTop": (16 * bUnit) + "px",
                            "marginBottom": (16 * bUnit) + "px"
                        }}
                    >
                        <RowUnit
                            customStyleAttr={{"marginRight": "auto"}}
                            sizeAttr={new SizeAttr(wp, (rightMiddleButtonPw - 10) + "px")}
                        >
                            <BoxUnit
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "borderRadius": (15.5 * bUnit) + "px",
                                    "background": "#2B2B2B",
                                    "marginRight": "auto"
                                })}
                                sizeAttr={new SizeAttr(wp, (110 * bUnit) + "px", (31 * bUnit) + "px")}>
                                <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginRight": (14 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (12 * bUnit) + "px")}
                                        url={exportMoreIcon}/>
                                    <SpanText
                                        colorAttr={new ColorAttr(wp, "#888888")}
                                        customStyleAttr={RowAttr.getChildrenPositionObj()}>
                                        {".bvh"}
                                    </SpanText>
                                </RowUnit>
                            </BoxUnit>

                            <BoxUnit
                                needHover={true}
                                onClick={() => {
                                    // window.open(tosLink(mixVideo.template.bvh), "_blank")
                                }}
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "borderRadius": (4 * bUnit) + "px",
                                    "border": "1px solid #888888"
                                })}
                                sizeAttr={new SizeAttr(wp,
                                    (rightMiddleButtonPw - 8 - (110 * bUnit) - 10) + "px", (32 * bUnit) + "px")}
                            >
                                <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginRight": (5 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (13.5 * bUnit) + "px")}
                                        url={exportVipIcon}/>
                                    <SpanText
                                        colorAttr={new ColorAttr(wp, "#888888")}
                                        customStyleAttr={RowAttr.getChildrenPositionObj()}>
                                        {"Export Motion"}
                                    </SpanText>
                                </RowUnit>
                            </BoxUnit>
                        </RowUnit>
                        <RowUnit
                            sizeAttr={new SizeAttr(wp, (rightMiddleButtonPw + 10) + "px")}
                        >
                            <BoxUnit
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "borderRadius": (15.5 * bUnit) + "px",
                                    "background": "#2B2B2B",
                                    "marginRight": "auto"
                                })}
                                sizeAttr={new SizeAttr(wp, (110 * bUnit) + "px", (31 * bUnit) + "px")}>
                                <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginRight": (14 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (12 * bUnit) + "px")}
                                        url={exportMoreIcon}/>
                                    <SpanText
                                        colorAttr={new ColorAttr(wp, "#888888")}
                                        customStyleAttr={RowAttr.getChildrenPositionObj()}>
                                        {".glb"}
                                    </SpanText>
                                </RowUnit>
                            </BoxUnit>
                            <BoxUnit
                                needHover={true}
                                onClick={() => {
                                    // window.open(tosLink(mixVideo.character.rig_model), "_blank")
                                }}
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "borderRadius": (4 * bUnit) + "px",
                                    "border": "1px solid #888888"
                                })}
                                sizeAttr={new SizeAttr(wp,
                                    (rightMiddleButtonPw - 8 - (110 * bUnit) + 10) + "px", (32 * bUnit) + "px")}
                            >
                                <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginRight": (5 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (13.5 * bUnit) + "px")}
                                        url={exportVipIcon}/>
                                    <SpanText
                                        colorAttr={new ColorAttr(wp, "#888888")}
                                        customStyleAttr={RowAttr.getChildrenPositionObj()}>
                                        {"Export Character"}
                                    </SpanText>
                                </RowUnit>
                            </BoxUnit>
                        </RowUnit>
                    </RowUnit>

                    <BoxUnit
                        colorAttr={new ColorAttr(wp, "", "#2E2E2E")}
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px", "1px")}/>

                    <RowUnit
                        customStyleAttr={{
                            "marginTop": (27 * bUnit) + "px"
                        }}
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px")}>
                        <ImageUnit
                            needHover={true}
                            onClick={() => {
                                showGenerationPopup({character: mixVideo.character, template: mixVideo.template})
                                closeClick()
                            }}
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "marginRight": (10 * bUnit) + "px"
                            })}
                            sizeAttr={new SizeAttr(wp, rightBottomIconSize + "px", rightBottomIconSize + "px")}
                            url={mixRegenIcon}/>
                        <ImageUnit
                            needHover={true}
                            onClick={() => {

                            }}
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "marginRight": "auto"
                            })}
                            sizeAttr={new SizeAttr(wp, rightBottomIconSize + "px", rightBottomIconSize + "px")}
                            url={mixShareIcon}/>

                        <RowUnit
                            colorAttr={new ColorAttr(wp, "#fafafa", "#242424")}
                            fontAttr={new FontAttr(wp, (14 * bUnit) + "px", "500")}
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "borderRadius": (18 * bUnit) + "px",
                                "marginRight": (10 * bUnit) + "px"
                            })}
                            sizeAttr={new SizeAttr(wp,
                                (117 * bUnit) + "px", (36 * bUnit) + "px")}>
                            <SpanText customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "marginRight": "auto",
                                "marginLeft": (19 * bUnit) + "px"
                            })}>
                                {"watermark"}
                            </SpanText>
                            <ImageUnit
                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                    "marginRight": (3 * bUnit) + "px"
                                })}
                                sizeAttr={new SizeAttr(wp, (30 * bUnit) + "px", (30 * bUnit) + "px")}
                                url={waterMarkOnIcon}/>
                        </RowUnit>

                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                window.open(tosLink(mixVideo.display_video), "_blank")
                            }}
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "borderRadius": (8 * bUnit) + "px",
                                "border": "1px solid #646464"
                            })}
                            sizeAttr={new SizeAttr(wp, (150 * bUnit) + "px", (36 * bUnit) + "px")}
                        >
                            <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                <ImageUnit
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginRight": (8 * bUnit) + "px",
                                        "transform": "translateY(-" + (bUnit) + "px"
                                    })}
                                    sizeAttr={new SizeAttr(wp, (20 * bUnit) + "px", (20 * bUnit) + "px")}
                                    url={mixDownloadIcon}/>
                                <SpanText
                                    fontAttr={new FontAttr(wp, (16 * bUnit) + "px", "500")}
                                    colorAttr={new ColorAttr(wp, "#EBEBEB")}
                                    customStyleAttr={RowAttr.getChildrenPositionObj()}>
                                    {"Download"}
                                </SpanText>
                            </RowUnit>
                        </BoxUnit>

                    </RowUnit>
                </ColumnUnit>
            </ColumnUnit>
        </BoxUnit>
    )
}