import {WindowParam} from "../../../library/context/WindowContext";
import {ColumnUnit} from "../../../library/atomic/unit/ColumnUnit";
import {SizeAttr} from "../../../library/basic/size";
import {largeRadius, mediumRadius, smallPadding} from "../display/layout";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../library/basic/compose";
import {TcCvTemplatesData} from "../data/TcCvTemplates";
import {TcCvCharactersData} from "../data/TcCvCharacters";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import {SpanText} from "../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../library/basic/font";
import {ColorAttr} from "../../../library/basic/color";
import discordJoinPc from "../../../assets/prompt/discord_join_pc.svg"
import detailCopyIcon from "../../../assets/prompt/detail_copy_icon.svg"
import emptyDisplayIcon from "../../../assets/prompt/empty_display_icon.svg";
import {ImageUnit} from "../../../library/atomic/image/ImageUnit";
import {tosLink} from "../../../library/util/tosUtil";
import {copyToClipboard} from "../../../library/util/storageUtil";
import {GLBPlayer} from "./GlbPlayer";
import {VideoSwitcher} from "./VideoSwitcher";

export function DetailPanelPc(wp: WindowParam, width: string, height: string, id: string, name: string, tags: string[],
                              cardInnerElement: (cardWidthNum: number, cardHeightNum: number) => JSX.Element,
                              selectHint: string, copyIconClick: () => void) {
    let widthNum = SizeAttr.getPxNumber(width)
    let heightNum = SizeAttr.getPxNumber(height)

    // discord按钮相关
    let discordButtonTopMarginNum = 24
    let discordButtonBottomMarginNum = 60
    let discordButtonWidthNum = 431
    let discordButtonHeightNum = 54

    // 名字需要的空间
    let textInfoRowMinHeight = 72

    // 详情卡片相关
    let detailCardWidthNum = widthNum - 64
    let detailCardTopMarginNum = 24
    let detailCardBottomMarginNum = 24

    let detailCardWantHeightNum = detailCardWidthNum * (651 / 531)
    let detailCardAvailableHeightNum = heightNum - (discordButtonTopMarginNum +
            discordButtonHeightNum + discordButtonBottomMarginNum) - (selectHint == "" ? textInfoRowMinHeight : 0) -
        detailCardTopMarginNum - detailCardBottomMarginNum
    let detailCardHeightNum = Math.min(detailCardWantHeightNum, detailCardAvailableHeightNum)


    return (
        <ColumnUnit sizeAttr={new SizeAttr(wp, width, height)}>
            <div
                style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "display": "flex",
                    "justifyContent": "center",
                    "marginTop": detailCardTopMarginNum + "px",
                    "marginBottom": detailCardBottomMarginNum + "px",
                    "width": detailCardWidthNum + "px",
                    "height": detailCardHeightNum + "px",
                    "borderRadius": largeRadius,
                    "background": "linear-gradient(to top, #4DD1C11A 0%, #4F78C81A 26%, #8F51B81A 51%, #BE57811A 76%, #BF73531A 100%)",
                    "backgroundClip": "padding-box",
                    "border": "2px solid rgba(255,255,255,0.16)",
                })}
            >
                {selectHint == "" && cardInnerElement(detailCardWidthNum, detailCardHeightNum)}
                {
                    selectHint != "" &&
                    <ColumnUnit customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                        "transform": "translateY(" + SizeAttr.pxMultiple((detailCardWidthNum) + "px", 24 / 531) + ")"
                    })}>
                        <img
                            style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                "width": SizeAttr.pxMultiple((detailCardWidthNum) + "px", 127 / 531),
                                "height": SizeAttr.pxMultiple((detailCardWidthNum) + "px", 118.34 / 531),
                                "marginBottom": SizeAttr.pxMultiple((detailCardWidthNum) + "px", 66 / 531),
                            })}
                            alt={"select"}
                            src={emptyDisplayIcon}/>
                        <SpanText
                            customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                "justifyContent": "center",
                                "textAlign": "center",
                                "maxWidth": SizeAttr.pxMultiple((detailCardWidthNum) + "px", 377 / 531)
                            })}
                            colorAttr={new ColorAttr(wp, "#E2E2EAB3")}
                            fontAttr={new FontAttr(wp, "30px", "500")}
                        >
                            {selectHint}
                        </SpanText>
                    </ColumnUnit>
                }
            </div>

            {
                selectHint == "" &&
                <ColumnUnit
                    customStyleAttr={BoxAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, detailCardWidthNum + "px")}>
                    <RowUnit>
                        <SpanText
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "justifyContent": ""
                            })}
                            colorAttr={new ColorAttr(wp, "#ffffff")}
                            fontAttr={new FontAttr(wp, "22px", "600")}
                        >
                            {name}
                        </SpanText>
                        <ImageUnit
                            onClick={() => {
                                copyToClipboard(name)
                                copyIconClick()
                            }}
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "marginLeft": "auto"
                            })}
                            sizeAttr={new SizeAttr(wp, "32px", "32px")}
                            url={detailCopyIcon}/>
                    </RowUnit>

                    <RowUnit customStyleAttr={{
                        "marginTop": smallPadding
                    }}>
                        {
                            tags.slice(0, Math.min(3, tags.length)).map((t) => {
                                return (
                                    <SpanText
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "justifyContent": "",
                                            "marginRight": smallPadding
                                        })}
                                        colorAttr={new ColorAttr(wp, "#B5B8C5")}
                                        fontAttr={new FontAttr(wp, "18px", "600")}
                                    >
                                        {"#" + t}
                                    </SpanText>
                                )
                            })
                        }
                        <SpanText
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "justifyContent": "",
                                "marginLeft": "auto"
                            })}
                            colorAttr={new ColorAttr(wp, "#5E5E5E")}
                            fontAttr={new FontAttr(wp, "16px", "400")}
                        >
                            {id}
                        </SpanText>
                    </RowUnit>

                </ColumnUnit>
            }

            <img
                onClick={() => {
                    window.open("https://discord.gg/aiuni", "_blank")
                }}
                className={"hoverCursor"}
                style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "marginTop": discordButtonTopMarginNum + "px",
                    "marginBottom": discordButtonBottomMarginNum + "px",
                    "width": discordButtonWidthNum + "px",
                    "height": discordButtonHeightNum + "px",
                })}
                src={discordJoinPc}
                alt={"join discord"}/>

        </ColumnUnit>
    )
}

export function TcTemplateDetailPanel(wp: WindowParam, width: string, height: string,
                                      tcCvTemplatesData: TcCvTemplatesData | undefined,
                                      copyIconClick: () => void) {
    let tags = []
    try {
        if (tcCvTemplatesData != undefined) {
            tags = JSON.parse(tcCvTemplatesData.tags)
        }
    } catch (e) {
        tags = []
    }

    return DetailPanelPc(wp, width, height, tcCvTemplatesData ? tcCvTemplatesData.id : "",
        tcCvTemplatesData ? "@ " + tcCvTemplatesData.name : "", tags,
        (w, h) => {
            if (tcCvTemplatesData && tcCvTemplatesData.display_video != "") {
                return <VideoSwitcher
                    key={"VideoSwitcher-template-pc-" + tcCvTemplatesData.id}
                    wp={wp}
                    w={w}
                    h={h}
                    switcherOffset={mediumRadius}
                    mixVideo={tcCvTemplatesData.display_video}
                    inpaintVideo={tcCvTemplatesData.inpaint_video}
                    oriVideo={tcCvTemplatesData.org_video}
                    switcherType={"template"}
                />
            }
            return (
                <div></div>
            )
        }, tcCvTemplatesData ? "" : "Select a template to display detailed information",
        copyIconClick)
}

export function TcCharacterDetailPanel(wp: WindowParam, width: string, height: string,
                                       tcCvCharactersData: TcCvCharactersData | undefined,
                                       copyIconClick: () => void) {
    let tags = []
    try {
        if (tcCvCharactersData != undefined) {
            tags = JSON.parse(tcCvCharactersData.tags)
        }
    } catch (e) {
        tags = []
    }

    return DetailPanelPc(wp, width, height, tcCvCharactersData ? tcCvCharactersData.id : "",
        tcCvCharactersData ? "@ " + tcCvCharactersData.name : "", tags,
        (w, h) => {
            if (tcCvCharactersData && tcCvCharactersData.rig_model != "") {
                return <GLBPlayer
                    key={"DetailPanelPc-GLBPlayer-" + tcCvCharactersData.rig_model}
                    src={tosLink(tcCvCharactersData.rig_model)}
                    width={(w - 64) + "px"}
                    height={(h - 8) + "px"}
                />
            }
            return (
                <div></div>
            )
        }, tcCvCharactersData ? "" : "Select a character to display detailed information",
        copyIconClick)
}