import {WindowParam} from "../../../../library/context/WindowContext";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {SizeAttr} from "../../../../library/basic/size";

export function PopupBaseBox(wp: WindowParam,
                             popUpElement: (closeClick: (triggerTask: boolean) => void) => JSX.Element,
                             closeClick: (triggerTask: boolean) => void, zIndex: string = "999") {
    return (
        <BoxUnit
            onClick={() => {
                closeClick(false)
            }}
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": zIndex,
                "background": "linear-gradient(to bottom,rgba(255,255,255,0.12),rgba(255,255,255,0.03))",
                "backdropFilter": "blur(20px)"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {popUpElement(closeClick)}
        </BoxUnit>
    )
}