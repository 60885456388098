import cvPromptLogo from "../../../../assets/prompt/nav/cv_prompt_logo.svg";
import "./TcCvPromptTopNav.css"
import {LabIndex} from "../../TcCvPromptPage";
import {WindowParam} from "../../../../library/context/WindowContext";
import {SizeAttr} from "../../../../library/basic/size";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import {RowAttr} from "../../../../library/basic/compose";
import {largePadding, mediumRadius} from "../../display/layout";
import {SpanHoverText} from "../../../../library/atomic/text/SpanHoverText";
import {FontAttr} from "../../../../library/basic/font";
import {ColorAttr} from "../../../../library/basic/color";

export function TcCvPromptTopNavPc(wp: WindowParam, navHeight: string, currentLabIndex: LabIndex,
                                   setLabIndex: (labIndex: LabIndex) => void) {
    let logoHeight = SizeAttr.pxMultiple(navHeight, 57 / 72)

    return (
        <RowUnit
            customStyleAttr={{
                "background": "linear-gradient(to bottom,rgba(153,153,153,0.03),rgba(255,255,255,0.15))"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", "72px")}>

            <ImageUnit
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginLeft": largePadding
                })}
                sizeAttr={new SizeAttr(wp, "", logoHeight)}
                url={cvPromptLogo}/>

            <SpanHoverText
                onClick={() => {
                    setLabIndex("template")
                }}
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginLeft": "36px"
                })}
                fontAttr={new FontAttr(wp, "24px", "600")}
                colorAttr={new ColorAttr(wp, "#E0E0E0")}
            >
                {"Template"}
            </SpanHoverText>

            <SpanHoverText
                onClick={() => {
                    setLabIndex("character")
                }}
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginLeft": "36px"
                })}
                fontAttr={new FontAttr(wp, "24px", "600")}
                colorAttr={new ColorAttr(wp, "#E0E0E0")}
            >
                {"Character"}
            </SpanHoverText>
        </RowUnit>
    )
}

export function TcCvPromptTopNavPhone(wp: WindowParam, navHeight: string, currentLabIndex: LabIndex,
                                      setLabIndex: (labIndex: LabIndex) => void) {
    let labIndexItem = function (labIndex: LabIndex) {
        return (
            <div
                onClick={() => {
                    setLabIndex(labIndex)
                }}
                className={`tc-cv-prompt-top-nav-item ${currentLabIndex == labIndex ? 'clicked' : ''}`}
                style={{
                    "width": "84px",
                    "height": "32px",
                    "display": "flex",
                    "zIndex": "19"
                }}>
                    <span style={{
                        "fontSize": "18px",
                        "fontWeight": "600",
                        "margin": "auto"
                    }}>
                        {labIndex == "template" ? "Template" : "Character"}
                    </span>
            </div>
        )
    }
    return (
        <div style={{
            "width": "100vw",
            "height": navHeight,
            "display": "flex"
        }}>

            <div style={{
                "width": "168px",
                "height": "32px",
                "marginLeft": "auto",
                "marginRight": "auto",
                "marginTop": "auto",
                "borderRadius": mediumRadius,
                "background": "#29292C",
                "display": "flex",
                "flexDirection": "row",
                "position": "relative"
            }}>
                <div
                    className={`tc-cv-prompt-top-nav-block ${currentLabIndex == "template" ? "left" : "right"}`}
                    style={{
                        "background": "#ffffff",
                        "width": "86px",
                        "height": "32px",
                        "borderRadius": mediumRadius,
                        "position": "absolute",
                        "zIndex": "9"
                    }}
                >
                </div>
                {labIndexItem("template")}
                {labIndexItem("character")}
            </div>

        </div>
    )
}
