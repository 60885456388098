export function cutStr(str: string, limit: number): string {
    if (!str || str.length <= limit) {
        return str
    }
    return str.slice(0, limit)
}

export function removeFileExtension(filename: string): string {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex > 0) {
        return filename.substring(0, dotIndex);
    }
    return filename;
}