import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {ColorAttr} from "../../../../library/basic/color";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import bottomGenerateIcon from "../../../../assets/user/nav/phone_bottom_generate_icon.svg";
import bottomMineSelectedIcon from "../../../../assets/user/nav/phone_bottom_mine_selected_icon.svg";
import bottomMineUnselectedIcon from "../../../../assets/user/nav/phone_bottom_mine_unselected_icon.svg";
import bottomUniverseSelectIcon from "../../../../assets/user/nav/phone_bottom_universe_selected_icon.svg";
import bottomUniverseUnselectedIcon from "../../../../assets/user/nav/phone_bottom_universe_unselected_icon.svg";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import {FontAttr} from "../../../../library/basic/font";
import {getToken, toLoginPage} from "../../util/account";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {useNavigate} from "react-router";

interface TcCvUserBottomNavProps {
    wp: WindowParam
    height: string
    showGenerationPopup: () => void
}

export const TcCvUserBottomNav: FC<TcCvUserBottomNavProps> = ({
                                                                  wp,
                                                                  height,
                                                                  showGenerationPopup
                                                              }: TcCvUserBottomNavProps) => {
    let isHome = window.location.href.indexOf("home") >= 0
    let isMine = window.location.href.indexOf("mine") >= 0

    const navigate = useNavigate();

    return (
        <RowUnit
            customStyleAttr={{
                "boxShadow": "0 -4px 20px 0 #8181814C",
            }}
            colorAttr={new ColorAttr(wp, "#222222")}
            sizeAttr={new SizeAttr(wp, "100vw", height)}>

            <ColumnUnit
                customStyleAttr={BoxAttr.getChildrenPositionObj()}
                needHover={true}
                onClick={() => {
                    if (!isHome) {
                        window.open("/", "_blank")
                    }
                }}
                colorAttr={new ColorAttr(wp, isHome ? "#ffffff" : "#989898")}
                fontAttr={new FontAttr(wp, "11px", "500")}
            >
                <ImageUnit
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, "23px", "20px")}
                    url={isHome ? bottomUniverseSelectIcon : bottomUniverseUnselectedIcon}/>
                <SpanText customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "marginTop": "2px"
                })}>
                    {"Universe"}
                </SpanText>
            </ColumnUnit>

            <ImageUnit
                needHover={true}
                onClick={() => {
                    showGenerationPopup()
                }}
                customStyleAttr={RowAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, "30px", "30px")}
                url={bottomGenerateIcon}/>

            <ColumnUnit
                customStyleAttr={BoxAttr.getChildrenPositionObj()}
                needHover={true}
                onClick={() => {
                    if (!isMine) {
                        if (!getToken()) {
                            toLoginPage("/mine", navigate)
                            return
                        }
                        window.open("/mine", "_blank")
                    }
                }}
                colorAttr={new ColorAttr(wp, isMine ? "#ffffff" : "#989898")}
                fontAttr={new FontAttr(wp, "11px", "500")}
            >
                <ImageUnit
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, "23px", "20px")}
                    url={isMine ? bottomMineSelectedIcon : bottomMineUnselectedIcon}/>
                <SpanText customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                    "marginTop": "2px"
                })}>
                    {"Mine"}
                </SpanText>
            </ColumnUnit>

        </RowUnit>
    )
}