import * as React from "react";
import {FC, useState} from "react";
import {WindowParam} from "../../../../library/context/WindowContext";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {SizeAttr} from "../../../../library/basic/size";
import aiuniLogoText from "../../../../assets/user/nav/aiuni_logo_text.svg";
import aiuniLogoIcon from "../../../../assets/user/nav/aiuni_logo_icon.svg";
import upgradePlanButton from "../../../../assets/user/nav/upgrade_plan_button.svg";
import userDefaultAvatar from "../../../../assets/user/nav/user_default_avatar.svg";
import userMenuExpandIcon from "../../../../assets/user/nav/user_menu_expand_icon.svg";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import {RowAttr} from "../../../../library/basic/compose";
import {mediumPadding, smallPadding} from "../../display/layout";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import {ColorAttr} from "../../../../library/basic/color";
import {accountLogout, getToken, toLoginPage} from "../../util/account";
import {homePath, toLink} from "../../../../library/util/linkUtil";
import {useNavigate} from "react-router";

interface TcCvUserTopNavPcProps {
    wp: WindowParam
    navHeight: string
    avatar: string
    username: string
}

export const TcCvUserTopNavPc: FC<TcCvUserTopNavPcProps> = ({
                                                                wp,
                                                                navHeight,
                                                                avatar,
                                                                username
                                                            }: TcCvUserTopNavPcProps) => {
    let logoHeight = SizeAttr.pxMultiple(navHeight, 57 / 78)
    let logoIconHeight = SizeAttr.pxMultiple(navHeight, 48 / 78)

    let upgradeAndUserPanelHeight = SizeAttr.pxMultiple(navHeight, 42 / 78)
    let userMenuExpandSize = SizeAttr.pxMultiple(navHeight, 18 / 78)

    const navigate = useNavigate();

    return (
        <RowUnit
            sizeAttr={new SizeAttr(wp, "100vw", navHeight)}>
            <RowUnit
                needHover={true}
                onClick={() => {
                    toLink(homePath, navigate)
                }}
                colorAttr={new ColorAttr(wp, "", "#141414")}
                sizeAttr={new SizeAttr(wp, "256px", navHeight)}>
                <ImageUnit
                    sizeAttr={new SizeAttr(wp, "", logoIconHeight)}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginLeft": "26px",
                        "marginRight": mediumPadding,
                        "transform": "translateY(-2px)"
                    })}
                    url={aiuniLogoIcon}/>
                <ImageUnit
                    sizeAttr={new SizeAttr(wp, "", logoHeight)}
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    url={aiuniLogoText}/>
            </RowUnit>
            <RowUnit
                colorAttr={new ColorAttr(wp, "", "#000000")}
                sizeAttr={new SizeAttr(wp, (wp.currWidth - 256) + "px", navHeight)}>
                <ImageUnit
                    sizeAttr={new SizeAttr(wp, "", upgradeAndUserPanelHeight)}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginLeft": "auto",
                        "marginRight": mediumPadding
                    })}
                    url={upgradePlanButton}/>

                <ImageUnit
                    sizeAttr={new SizeAttr(wp, upgradeAndUserPanelHeight, upgradeAndUserPanelHeight)}
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    innerImgStyle={{
                        "borderRadius": SizeAttr.pxDivide(upgradeAndUserPanelHeight, 2)
                    }}
                    url={avatar ? avatar : userDefaultAvatar}/>

                <RowUnit
                    needHover={true}
                    onClick={() => {
                        accountLogout()
                        toLoginPage(window.location.pathname, navigate)
                    }}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginLeft": "10px",
                        "marginRight": "26px"
                    })}
                >
                    <SpanText
                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                            "marginRight": "10px"
                        })}
                        colorAttr={new ColorAttr(wp, "#989898")}
                        fontAttr={new FontAttr(wp, "20px", "600")}>
                        {username ? username : "User Name"}
                    </SpanText>
                    <ImageUnit
                        sizeAttr={new SizeAttr(wp, userMenuExpandSize, userMenuExpandSize)}
                        customStyleAttr={RowAttr.getChildrenPositionObj()}
                        url={userMenuExpandIcon}/>
                </RowUnit>
            </RowUnit>
        </RowUnit>
    )
}


export const TcCvUserTopNavPhone: FC<TcCvUserTopNavPcProps> = ({
                                                                   wp,
                                                                   navHeight,
                                                                   avatar,
                                                                   username
                                                               }: TcCvUserTopNavPcProps) => {
    let logoHeight = SizeAttr.pxMultiple(navHeight, 54 / 78)
    let logoIconHeight = SizeAttr.pxMultiple(navHeight, 45 / 78)

    let upgradeAndUserPanelHeight = SizeAttr.pxMultiple(navHeight, 28 / 48)
    let userMenuExpandSize = SizeAttr.pxMultiple(navHeight, 18 / 78)

    const navigate = useNavigate();

    return (
        <RowUnit
            customStyleAttr={{
                "boxShadow": "0 4px 4px 0 rgba(255, 255, 255, 0.25)",
            }}
            colorAttr={new ColorAttr(wp, "", "#121212")}
            sizeAttr={new SizeAttr(wp, "100vw", navHeight)}>
            <RowUnit
                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                    "marginRight": "auto"
                })}
                needHover={true}
                onClick={() => {
                    toLink(homePath, navigate)
                }}
            >
                <ImageUnit
                    sizeAttr={new SizeAttr(wp, SizeAttr.pxMultiple(logoHeight, 120 / 130), logoIconHeight)}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginLeft": mediumPadding,
                        "marginRight": smallPadding,
                        "transform": "translateY(-1px)"
                    })}
                    url={aiuniLogoIcon}/>
                <ImageUnit
                    sizeAttr={new SizeAttr(wp, SizeAttr.pxMultiple(logoHeight, 65 / 32), logoHeight)}
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    url={aiuniLogoText}/>
            </RowUnit>
            <RowUnit
                customStyleAttr={RowAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, "", navHeight)}>
                <ImageUnit
                    sizeAttr={new SizeAttr(wp, upgradeAndUserPanelHeight, upgradeAndUserPanelHeight)}
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    innerImgStyle={{
                        "borderRadius": SizeAttr.pxDivide(upgradeAndUserPanelHeight, 2)
                    }}
                    url={avatar ? avatar : userDefaultAvatar}/>

                <RowUnit
                    needHover={true}
                    onClick={() => {
                        accountLogout()
                        toLoginPage(window.location.pathname, navigate)
                    }}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginLeft": "6px",
                        "marginRight": mediumPadding
                    })}
                >
                    <SpanText
                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                            "marginRight": "6px"
                        })}
                        colorAttr={new ColorAttr(wp, "#989898")}
                        fontAttr={new FontAttr(wp, "15px", "600")}>
                        {username ? username : "User Name"}
                    </SpanText>
                    <ImageUnit
                        sizeAttr={new SizeAttr(wp, userMenuExpandSize, userMenuExpandSize)}
                        customStyleAttr={RowAttr.getChildrenPositionObj()}
                        url={userMenuExpandIcon}/>
                </RowUnit>
            </RowUnit>
        </RowUnit>
    )
}