import {UserInfo} from "../data/TcCvUser";
import {TC_AIUNI_GO_BASE_URL} from "../../../library/util/apiUtil";
import {fetchStrParam, homePath, toLink} from "../../../library/util/linkUtil";
import {NavigateFunction} from "react-router";

function getCookie(name: string): string | null {
    let cookieArr = document.cookie.split(";");
    for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if the cookie wasn't found
    return null;
}

export function toLoginPage(backUrl: string, navigate: NavigateFunction | undefined = undefined) {
    if (backUrl.startsWith("/") || backUrl == "") {
        backUrl = "https://aiuni.ai" + backUrl
    }
    toLink("/login?backUrl=" + window.btoa(backUrl), navigate)
}

export function getBackUrl(): string {
    return fetchStrParam("backUrl") ? fetchStrParam("backUrl") : window.btoa("https://aiuni.ai" + homePath)
}

export function getLoginSource(): string {
    return fetchStrParam("source")
}

export function getLoginAuth(): string {
    return fetchStrParam("auth_state")
}

export function getEmailAuth(): string {
    return fetchStrParam("auth")
}

const AIUNI_AI_TOKEN = "AIUNI_AI_TOKEN"
const AIUNI_AI_USERINFO = "AIUNI_AI_USERINFO"

const testToken = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1IjoyMzYzMDY1MDU3MDAyNjkxNzAsImMiOjEsImV4cCI6MCwic3ViIjpudWxsLCJlbWFpbCI6bnVsbCwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV9udW1iZXIiOm51bGwsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsInBpY3R1cmUiOm51bGwsIm5pY2tuYW1lIjpudWxsfQ.N6fKc9TRGS-rEl5huIIg9I6zZIS_5DRtl_necl803sM"

export function getToken(): string {
    let l = localStorage.getItem(AIUNI_AI_TOKEN)
    if (!l) {
        return ""
    }
    return l
}

export function saveToken(token: string) {
    localStorage.setItem(AIUNI_AI_TOKEN, token);
}

export function getUserInfo(): UserInfo | undefined {
    let l = localStorage.getItem(AIUNI_AI_USERINFO)
    if (!l) {
        return undefined
    }
    let user = JSON.parse(l)
    if (!user) {
        return undefined
    }
    return user as UserInfo
}

export function saveUserInfo(userInfo: UserInfo) {
    if (!getToken()) {
        saveToken("use cookie")
    }
    localStorage.setItem(AIUNI_AI_USERINFO, JSON.stringify(userInfo));
}

export function getUsername(): string {
    let u = getUserInfo()
    if (u && u.user) {
        return u.user.nickname
    }
    return ""
}

export function getUserAvatar(): string {
    let u = getUserInfo()
    if (u && u.user) {
        return u.user.picture
    }
    return ""
}

export function accountLogout() {
    localStorage.removeItem(AIUNI_AI_TOKEN);
    localStorage.removeItem(AIUNI_AI_USERINFO);
}

const CHARACTER_GUIDE_NOT_REMIND = "CHARACTER_GUIDE_NOT_REMIND"
const TEMPLATE_GUIDE_NOT_REMIND = "TEMPLATE_GUIDE_NOT_REMIND"

export function getCharacterGuideNotRemind(): boolean {
    return localStorage.getItem(CHARACTER_GUIDE_NOT_REMIND) == "true"
}

export function setCharacterGuideNotRemind() {
    localStorage.setItem(CHARACTER_GUIDE_NOT_REMIND, "true")
}

export function getTemplateGuideNotRemind(): boolean {
    return localStorage.getItem(TEMPLATE_GUIDE_NOT_REMIND) == "true"
}

export function setTemplateGuideNotRemind() {
    localStorage.setItem(TEMPLATE_GUIDE_NOT_REMIND, "true")
}


export type SendEmailCodeReq = {
    email: string
}

export type SendEmailCodeResp = {
    error: number
    message: string
    msg: string
    data: {
        email: string
        sms_code: string
    }
}

export function sendEmailCode(req: SendEmailCodeReq, callback: (success: boolean, resp?: SendEmailCodeResp) => void) {
    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
    };

    fetch(TC_AIUNI_GO_BASE_URL + "/getcode", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as SendEmailCodeResp;
                callback(resp.error == 0, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type LoginByEmailAndCodeReq = {
    email: string
    sms_code: string
}

export type LoginByEmailAndCodeResp = {
    error: number
    message: string
    msg: string
    data: {
        token: string
    }
}

export function loginByEmailAndCode(req: LoginByEmailAndCodeReq, callback: (success: boolean, resp?: LoginByEmailAndCodeResp) => void) {
    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
    };

    fetch(TC_AIUNI_GO_BASE_URL + "/login", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as LoginByEmailAndCodeResp;
                callback(resp.error == 0, resp)
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export function googleLogin() {
    let backUrl = getBackUrl()
    // 如果需要邀请码，将inviteCheckUrl解码后，拼接上auth参数
    let inviteCheckUrl = "https://aiuni.ai/login?backUrl=" + backUrl + "&source=google";
    window.location.href = TC_AIUNI_GO_BASE_URL + "/google/login?source=cv&inviteCheckUrl="
        + window.btoa(inviteCheckUrl) + "&backUrl=" + backUrl
}

export function discordLogin() {
    let backUrl = getBackUrl()
    // 如果需要邀请码，将inviteCheckUrl解码后，拼接上auth参数
    let inviteCheckUrl = "https://aiuni.ai/login?backUrl=" + backUrl + "&source=discord";
    window.location.href = TC_AIUNI_GO_BASE_URL + "/discord/login?source=cv&inviteCheckUrl="
        + window.btoa(inviteCheckUrl) + "&backUrl=" + backUrl
}

export type LoginJoinReq = {
    invite_code: string
    token: string
    auth_state: string
}

export type LoginJoinResp = {
    error: number
    message: string
    msg: string
    data: {
        token: string
    }
}

export function loginByJoin(req: LoginJoinReq, callback: (success: boolean, resp?: LoginByEmailAndCodeResp) => void) {
    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
    };

    fetch(TC_AIUNI_GO_BASE_URL + "/join", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as LoginJoinResp;
                callback(resp.error == 0, resp)
            } catch (e) {
                callback(false, undefined);
            }
        })
}
