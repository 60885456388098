import {WindowParam} from "../../../library/context/WindowContext";
import * as React from "react";
import {FC, useState} from "react";
import {ColumnUnit} from "../../../library/atomic/unit/ColumnUnit";
import {SizeAttr} from "../../../library/basic/size";
import {BoxAttr, RowAttr} from "../../../library/basic/compose";
import {tosLink} from "../../../library/util/tosUtil";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import mixSelected from "../../../assets/prompt/video/mix_selected.svg";
import mixUnselected from "../../../assets/prompt/video/mix_unselected.svg";
import inpaintSelected from "../../../assets/prompt/video/inpaint_selected.svg";
import inpaintUnselected from "../../../assets/prompt/video/inpaint_unselected.svg";
import oriSelected from "../../../assets/prompt/video/ori_selected.svg";
import oriUnselected from "../../../assets/prompt/video/ori_unselected.svg";
import mixSwitcherIcon from "../../../assets/prompt/video/mix_switcher_icon.svg";
import {ImageUnit} from "../../../library/atomic/image/ImageUnit";
import {BoxUnit} from "../../../library/atomic/unit/BoxUnit";
import {ColorAttr} from "../../../library/basic/color";

export type VideoType = "mix" | "inpaint" | "ori"
export type SwitcherType = "mix" | "template"

interface VideoSwitcherComponentProps {
    wp: WindowParam
    videoType: VideoType
    setVideoType: (newVideoType: VideoType) => void
    switcherType: SwitcherType
    customStyleAttr?: {}
}

export const VideoSwitcherComponent: FC<VideoSwitcherComponentProps> = ({
                                                                            wp,
                                                                            videoType,
                                                                            setVideoType,
                                                                            switcherType,
                                                                            customStyleAttr
                                                                        }: VideoSwitcherComponentProps) => {
    if (switcherType == "mix") {
        return (
            <BoxUnit
                key={"VideoSwitcherComponent-mix-" + videoType}
                needHover={true}
                onClick={() => {
                    if (videoType == "ori") {
                        setVideoType("mix")
                    } else {
                        setVideoType("ori")
                    }
                }}
                customStyleAttr={Object.assign({
                    "borderRadius": "4px",
                    "zIndex": 20
                }, customStyleAttr)}
                colorAttr={new ColorAttr(wp, "", "#222222")}
                sizeAttr={new SizeAttr(wp, "24px", "24px")}>
                <ImageUnit
                    customStyleAttr={BoxAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, "18px", "18px")}
                    url={mixSwitcherIcon} />
            </BoxUnit>
        )
    }

    return (
        <RowUnit
            key={"VideoSwitcherComponent-" + videoType}
            customStyleAttr={Object.assign({
                "borderRadius": "19px",
                "background": "linear-gradient(to bottom,#202020,#383838)",
                "zIndex": 20
            }, customStyleAttr)}
            sizeAttr={new SizeAttr(wp, "126px", "36px")}
        >
            <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                <ImageUnit
                    onClick={() => {
                        setVideoType("mix")
                    }}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginRight": "9px"
                    })}
                    sizeAttr={new SizeAttr(wp, "24px", "24px")}
                    url={videoType == "mix" ? mixSelected : mixUnselected}
                />
                <ImageUnit
                    onClick={() => {
                        setVideoType("inpaint")
                    }}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginRight": "9px"
                    })}
                    sizeAttr={new SizeAttr(wp, "24px", "24px")}
                    url={videoType == "inpaint" ? inpaintSelected : inpaintUnselected}
                />
                <ImageUnit
                    onClick={() => {
                        setVideoType("ori")
                    }}
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "marginRight": "0px"
                    })}
                    sizeAttr={new SizeAttr(wp, "24px", "24px")}
                    url={videoType == "ori" ? oriSelected : oriUnselected}
                />
            </RowUnit>
        </RowUnit>
    )
}

interface VideoSwitcherProps {
    wp: WindowParam
    w: number
    h: number
    switcherOffset: string
    mixVideo: string
    inpaintVideo: string
    oriVideo: string
    switcherType: SwitcherType
}

export const VideoSwitcher: FC<VideoSwitcherProps> = ({
                                                          wp,
                                                          w,
                                                          h,
                                                          switcherOffset,
                                                          mixVideo,
                                                          inpaintVideo,
                                                          oriVideo,
                                                          switcherType
                                                      }: VideoSwitcherProps) => {
    const [currentVideoType, setCurrentVideoType] = useState<VideoType>("mix");


    return (
        <ColumnUnit
            customStyleAttr={{
                "position": "relative"
            }}
            sizeAttr={new SizeAttr(wp, w + "px", h + "px")}
        >
            <VideoSwitcherComponent
                wp={wp}
                videoType={currentVideoType}
                setVideoType={setCurrentVideoType}
                switcherType={switcherType}
                customStyleAttr={{
                    "position": "absolute",
                    "top": switcherOffset,
                    "left": switcherOffset
                }}/>

            {
                currentVideoType == "mix" &&
                <video
                    autoPlay={true}
                    controls={true}
                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                        "maxWidth": (w - 64) + "px",
                        "maxHeight": (h) + "px",
                        "zIndex": 1
                    })}
                    src={tosLink(mixVideo)}/>
            }
            {
                currentVideoType == "inpaint" &&
                <video
                    autoPlay={true}
                    controls={true}
                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                        "maxWidth": (w - 64) + "px",
                        "maxHeight": (h) + "px",
                        "zIndex": 1
                    })}
                    src={tosLink(inpaintVideo)}/>
            }
            {
                currentVideoType == "ori" &&
                <video
                    autoPlay={true}
                    controls={true}
                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                        "maxWidth": (w - 64) + "px",
                        "maxHeight": (h) + "px",
                        "zIndex": 1
                    })}
                    src={tosLink(oriVideo)}/>
            }
        </ColumnUnit>
    )
}
