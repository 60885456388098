import {Component} from "react";
import {WindowParamContext} from "../../library/context/WindowContext";
import "../../assets/fonts/gina/stylesheet.css";

export class TcCvRedirectPage extends Component {
    static contextType = WindowParamContext;

    state: {} = {}

    componentDidMount() {
        window.location.replace('/prompt/template');
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    return (
                        <div></div>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}