import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC} from "react";
import {ColorAttr} from "../../../../library/basic/color";
import {BoxAttr, RowAttr} from "../../../../library/basic/compose";
import {SizeAttr} from "../../../../library/basic/size";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import "./PopupButton.css";

interface PopupButtonProps {
    wp: WindowParam
    width: string,
    height: string,
    fontSize: string,
    fontWeight?: string,
    text: string,
    strokeColor: string,
    strokeWidth?: string,
    fillColor: string,
    icon?: () => JSX.Element,
    borderRadius?: string
    click: () => void,
    customStyleAttr: any
}

export const PopupButton: FC<PopupButtonProps> = ({
                                                      wp,
                                                      width,
                                                      height,
                                                      fontSize,
                                                      fontWeight,
                                                      text,
                                                      strokeColor,
                                                      strokeWidth,
                                                      fillColor,
                                                      icon,
                                                      borderRadius,
                                                      click,
                                                      customStyleAttr
                                                  }: PopupButtonProps) => {
    let sw = strokeWidth ? strokeWidth : "1px"
    let gap = SizeAttr.pxMultiple(sw, 2)

    return (
        <RowUnit
            onClick={() => {
                click()
            }}
            classname={"popup-button"}
            colorAttr={new ColorAttr(wp, "#ffffff", strokeColor)}
            customStyleAttr={Object.assign({
                "borderRadius": borderRadius ? borderRadius : "10px"
            }, customStyleAttr)}
            sizeAttr={new SizeAttr(wp, width, height)}>

            <RowUnit
                colorAttr={new ColorAttr(wp, "", fillColor)}
                customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                    "borderRadius": borderRadius ? borderRadius : "10px"
                })}
                sizeAttr={new SizeAttr(wp, SizeAttr.pxMinus(width, gap), SizeAttr.pxMinus(height, gap))}>
                <RowUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                    <SpanText
                        customStyleAttr={RowAttr.getChildrenPositionObj()}
                        fontAttr={new FontAttr(wp, fontSize, fontWeight ? fontWeight : "500")}>
                        {text}
                    </SpanText>
                    {
                        icon && icon()
                    }
                </RowUnit>
            </RowUnit>
        </RowUnit>
    )
}