import {WindowParam} from "../../../../library/context/WindowContext";
import {SizeAttr} from "../../../../library/basic/size";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {DisplayMode, largePadding, largeRadius} from "../../display/layout";
import copyOkIcon from "../../../../assets/prompt/copy/copy_ok_icon.svg";
import copyPanelCloseIcon from "../../../../assets/prompt/copy/copy_panel_close_icon.svg";
import copyOkButtonPc from "../../../../assets/prompt/copy/copy_ok_button_pc.svg";
import copyJoinDiscordButtonPc from "../../../../assets/prompt/copy/copy_join_discord_button_pc.svg";
import copyOkButtonMobile from "../../../../assets/prompt/copy/copy_ok_button_mobile.svg";
import copyJoinDiscordButtonMobile from "../../../../assets/prompt/copy/copy_join_discord_button_mobile.svg";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import {ColorAttr} from "../../../../library/basic/color";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";

export function PasteOkPanelPc(wp: WindowParam, hideCopyPanel: () => void) {
    return (
        <BoxUnit
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": "1999",
                "background": "linear-gradient(to bottom,rgba(255,255,255,0.12),rgba(255,255,255,0.03))",
                "backdropFilter": "blur(20px)"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {InnerPastePanel(wp, hideCopyPanel, "pc")}
        </BoxUnit>
    )
}

export function PasteOkPanelPad(wp: WindowParam, hideCopyPanel: () => void) {
    return (
        <BoxUnit
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": "1999",
                "background": "#010101"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {InnerPastePanel(wp, hideCopyPanel, "pad")}
        </BoxUnit>
    )
}

export function PasteOkPanelMobile(wp: WindowParam, hideCopyPanel: () => void) {
    return (
        <BoxUnit
            customStyleAttr={{
                "position": "fixed",
                "top": "0",
                "zIndex": "1999",
                "background": "#010101"
            }}
            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>
            {InnerPastePanel(wp, hideCopyPanel, "mobile")}
        </BoxUnit>
    )
}

function InnerPastePanel(wp: WindowParam, hideCopyPanel: () => void, displayMode: DisplayMode) {
    let pWidthNum = 642
    let pHeightNum = 435.94
    if (displayMode == "mobile") {
        pWidthNum = wp.currWidth - 16
        pHeightNum = pWidthNum * (316 / 376)
    }

    let pSize = new SizeAttr(wp, pWidthNum + "px", pHeightNum + "px")

    let bCus = Object.assign(BoxAttr.getChildrenPositionObj(), {
        "borderRadius": largeRadius,
        "border": "1.72px solid #626262",
        "backgroundColor": "#3C3C3D80",
        "boxShadow": "0 2px 17.2px 0px rgba(255, 255, 255, 0.3)",
        "backdropFilter": "blur(171.99px)"
    })

    return (
        <BoxUnit
            customStyleAttr={bCus}
            sizeAttr={pSize}
        >
            <ColumnUnit
                customStyleAttr={Object.assign({
                    "position": "relative"
                }, bCus)}
                sizeAttr={pSize}
            >
                <img
                    className={"hoverCursor"}
                    onClick={() => {
                        hideCopyPanel()
                    }}
                    style={{
                        "width": displayMode == "mobile" ? "18px" : "24px",
                        "height": displayMode == "mobile" ? "18px" : "24px",
                        "position": "absolute",
                        "top": "24px",
                        "right": "24px",
                        "zIndex": "2000",
                    }}
                    alt={"close"}
                    src={copyPanelCloseIcon}/>

                <ColumnUnit customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                    "transform": displayMode == "mobile" ? "translateY(-4px)" : "translateY(-8px)"
                })}>
                    <img
                        style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                            "width": displayMode == "mobile" ? pWidthNum * (66.13 / 376) + "px" : "106.13px",
                            "height": displayMode == "mobile" ? pWidthNum * (66.13 / 376) + "px" : "106.13px",
                            "marginBottom": "12px"
                        })}
                        alt={"ok"}
                        src={copyOkIcon}/>

                    <SpanText
                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                            "marginBottom": "6px"
                        })}
                        colorAttr={new ColorAttr(wp, "#E2E2EA")}
                        fontAttr={new FontAttr(wp, displayMode == "mobile" ? "24px" : "36px", "600")}
                    >
                        {"Prompt Copied Successfully"}
                    </SpanText>

                    <SpanText
                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                            "maxWidth": displayMode == "mobile" ? pWidthNum * (337 / 376) + "px" : "506px",
                            "marginBottom": displayMode == "mobile" ? "27px" : "57px",
                            "textAlign": "center"
                        })}
                        colorAttr={new ColorAttr(wp, "#E2E2EA")}
                        fontAttr={new FontAttr(wp, displayMode == "mobile" ? "16px" : "24px", "500")}
                    >
                        {"Paste the prompt when using the commands ‘/animate,’ ‘/ideate,’ or ‘/character’ in our Discord server"}
                    </SpanText>

                    <RowUnit customStyleAttr={ColumnAttr.getChildrenPositionObj()}>
                        <img
                            className={"hoverCursor"}
                            onClick={() => {
                                hideCopyPanel()
                            }}
                            style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "width": displayMode == "mobile" ? pWidthNum * (62 / 376) + "px" : "187px",
                                "height": displayMode == "mobile" ? pWidthNum * (34 / 376) + "px" : "43px",
                                "marginRight": largePadding
                            })}
                            alt={"ok button"}
                            src={displayMode == "mobile" ? copyOkButtonMobile : copyOkButtonPc}/>
                        <img
                            onClick={() => {
                                window.open("https://discord.gg/aiuni", "_blank")
                            }}
                            className={"hoverCursor"}
                            style={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "width": displayMode == "mobile" ? pWidthNum * (133 / 376) + "px" : "187px",
                                "height": displayMode == "mobile" ? pWidthNum * (34 / 376) + "px" : "43px"
                            })}
                            alt={"join discord button"}
                            src={displayMode == "mobile" ? copyJoinDiscordButtonMobile : copyJoinDiscordButtonPc}/>
                    </RowUnit>
                </ColumnUnit>
            </ColumnUnit>
        </BoxUnit>
    )
}