import {WindowParam} from "../../../library/context/WindowContext";
import {ColumnUnit} from "../../../library/atomic/unit/ColumnUnit";
import {SizeAttr} from "../../../library/basic/size";
import {getDisplayMode, mediumRadius, smallPadding} from "../display/layout";
import {SpanText} from "../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../library/basic/font";
import {ColorAttr} from "../../../library/basic/color";
import {addCvTemplates, queryCvTemplatesByJobId, TcCvTemplatesData} from "../data/TcCvTemplates";
import {addCvCharacters, queryCvCharactersByJobId, TcCvCharactersData} from "../data/TcCvCharacters";
import {tosLink, UploaderMergeUrl, UploaderTouchUrl, UploaderTransportUrl} from "../../../library/util/tosUtil";
import {LabIndex} from "../TcCvPromptPage";
import {queryCvMixVideosByJobId, TcCvMixVideosData} from "../data/TcCvMixVideos";
import * as React from "react";
import {FC, useEffect, useState} from "react";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../library/basic/compose";
import {JobStatusError, JobStatusOK, queryCvJobsById, TcCvJobsData} from "../data/TcCvJob";
import pagingCardProcessingIcon from "../../../assets/prompt/paging_card_processing_icon.svg";
import uploader from "./uploader/web";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";

export function PagingCard(wp: WindowParam, width: string, fontSize: string, marginRight: string,
                           cover: string, video: string, hint: string, selected: boolean, labIndex: LabIndex, onClick: () => void,
                           jobData: TcCvJobsData, file?: File, fileUuid?: string, characterCover?: string, mouseEnterCallback?: () => void) {
    let cardWidth = width
    let cardImgWidth = SizeAttr.pxMinus(width, "4px")
    let cardImgHeight = SizeAttr.pxMultiple(cardImgWidth, 4 / 3)
    let cardHeight = SizeAttr.pxPlus(cardImgHeight, "4px")

    let hintLimit = "acgdance_tellme".length
    if (getDisplayMode(wp.currWidth) != "mobile") {
        hintLimit = "dancechallenge_gr".length
        if (labIndex == "mixVideo") {
            hintLimit -= 4
        }
    }

    let rawSelectedFill = "linear-gradient(to top, #4DD1C1 0%, #4F78C8 26%, #8F51B8 51%, #BE5781 76%, #BF7353 100%)"
    let selectedFill = "linear-gradient(to top, #4DD1C133 0%, #4F78C833 26%, #8F51B833 51%, #BE578133 76%, #BF735333 100%)"

    let showJob = (jobData.id != "" && jobData.id != "0") || (file != undefined && fileUuid != undefined)

    let processingImgWidth = SizeAttr.pxMultiple(cardImgWidth, 67 / 195)
    let processingImgHeight = SizeAttr.pxMultiple(cardImgWidth, 70 / 195)
    let processingBottomMargin = SizeAttr.pxMultiple(cardImgWidth, 10 / 195)

    return (
        <ColumnUnit
            sizeAttr={new SizeAttr(wp, width)}
            customStyleAttr={{
                "maxWidth": width,
                "marginRight": marginRight
            }}
        >
            <div
                onMouseEnter={() => {
                    if (mouseEnterCallback) {
                        mouseEnterCallback()
                    }
                }}
                onClick={() => {
                    if (!showJob) {
                        onClick()
                    }
                }}
                style={{
                    "display": "flex",
                    "width": cardWidth,
                    "height": cardHeight,
                    "borderRadius": mediumRadius,
                    "background": (selected || showJob) ? rawSelectedFill : "rgba(255,255,255,0.16)",
                    "backgroundClip": "padding-box"
                }}
            >
                {
                    showJob &&
                    <div
                        style={{
                            "margin": "auto",
                            "width": cardImgWidth,
                            "height": cardImgHeight,
                            "borderRadius": mediumRadius,
                            "background": selectedFill,
                        }}
                    >
                        <div
                            style={{
                                "margin": "auto",
                                "width": cardImgWidth,
                                "height": cardImgHeight,
                                "borderRadius": mediumRadius,
                                "background": "#000000",
                            }}
                        >
                            <div
                                style={{
                                    "margin": "auto",
                                    "width": cardImgWidth,
                                    "height": cardImgHeight,
                                    "borderRadius": mediumRadius,
                                    "background": selectedFill,
                                    "display": "flex"
                                }}
                            >
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <img
                                        style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "width": processingImgWidth,
                                            "height": processingImgHeight,
                                            "marginBottom": processingBottomMargin
                                        })}
                                        alt={""}
                                        src={pagingCardProcessingIcon}/>
                                    <SpanText
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "justifyContent": "center",
                                            "textAlign": "center",
                                        })}
                                        colorAttr={new ColorAttr(wp, "#FFFFFF")}
                                        fontAttr={new FontAttr(wp, "18px", "500")}
                                    >
                                        {
                                            file != undefined ? "Uploading" :
                                                (jobData.status == JobStatusError ? "Task Error" : (labIndex == "mixVideo" ? "Generating" : "Processing"))
                                        }
                                    </SpanText>
                                </ColumnUnit>
                            </div>
                        </div>
                    </div>
                }
                {
                    !showJob && (labIndex == "template" || labIndex == "mixVideo") && video == "" && cover &&
                    <img
                        className={"hoverCursor"}
                        style={{
                            "margin": "auto",
                            "width": cardImgWidth,
                            "height": cardImgHeight,
                            "objectFit": "cover",
                            "borderRadius": mediumRadius,
                            "border": "2px solid rgba(255,255,255,0.16)",
                        }}
                        alt={""}
                        src={cover}/>
                }
                {
                    !showJob && (labIndex == "template" || labIndex == "mixVideo") && video &&
                    <video
                        className={"hoverCursor"}
                        onContextMenu={(e) => {
                            e.preventDefault()
                        }}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        poster={cover}
                        controls={false}
                        style={{
                            "margin": "auto",
                            "width": cardImgWidth,
                            "height": cardImgHeight,
                            "objectFit": "cover",
                            "borderRadius": mediumRadius,
                            "border": "2px solid rgba(255,255,255,0.16)",
                        }}
                        src={video}/>
                }
                {
                    !showJob && labIndex == "character" && cover &&
                    <div
                        style={{
                            "margin": "auto",
                            "width": cardImgWidth,
                            "height": cardImgHeight,
                            "borderRadius": mediumRadius,
                            "border": selected ? "" : "2px solid rgba(255,255,255,0.16)",
                            "background": selected ? "#000000" : "",
                        }}
                    >
                        <div
                            style={{
                                "margin": "auto",
                                "width": cardImgWidth,
                                "height": cardImgHeight,
                                "borderRadius": mediumRadius,
                                "background": selected ? selectedFill : "",
                            }}
                        >
                            <img
                                className={"hoverCursor"}
                                style={{
                                    "width": cardImgWidth,
                                    "height": cardImgHeight,
                                    "objectFit": "contain",
                                    "borderRadius": mediumRadius
                                }}
                                alt={""}
                                src={cover}/>
                        </div>
                    </div>
                }
            </div>

            <RowUnit
                colorAttr={new ColorAttr(wp, selected ? "#E3E3E3" : "#A1A1A1")}
                fontAttr={new FontAttr(wp, fontSize, selected ? "600" : "400")}
                customStyleAttr={{
                    "maxWidth": width,
                    "justifyContent": "",
                    "marginTop": smallPadding
                }}
            >
                {
                    !showJob && labIndex == "mixVideo" && characterCover &&
                    <img
                        alt={"c"}
                        style={Object.assign(RowAttr.getChildrenPositionObj(), {
                            "width": "16px",
                            "height": "16px",
                            "borderRadius": "8px",
                            "marginRight": "6px",
                            "transform": "translateY(-2px)"
                        })}
                        src={characterCover}/>
                }
                <SpanText customStyleAttr={RowAttr.getChildrenPositionObj()}>
                    {hint.slice(0, hint.length > hintLimit ? hintLimit : hint.length)}
                </SpanText>
            </RowUnit>


        </ColumnUnit>
    )
}

interface TcTemplateCardProps {
    wp: WindowParam
    width: string
    fontSize: string
    marginRight: string,
    tcCvTemplatesData: TcCvTemplatesData,
    selected: boolean,
    onClick: (d: TcCvTemplatesData) => void
}

export const TcTemplateCard: FC<TcTemplateCardProps> = ({
                                                            wp,
                                                            width,
                                                            fontSize,
                                                            marginRight,
                                                            tcCvTemplatesData,
                                                            selected,
                                                            onClick
                                                        }: TcTemplateCardProps) => {
    const [data, setData] = useState<TcCvTemplatesData>(tcCvTemplatesData);
    const [isMouseEntered, setIsMouseEntered] = useState<boolean>(false);

    useEffect(() => {
        let checkJob = () => {
            if (data.job.id == "" || data.job.id == "0") {
                return
            }
            setTimeout(() => {
                let queryResultData = () => {
                    queryCvTemplatesByJobId(data.job.id, (success, resp) => {
                        if (success && resp && resp.data.id != "" && resp.data.id != "0") {
                            resp.data.job.id = ""
                            setData(resp.data)
                        } else {
                            checkJob()
                        }
                    })
                }

                if (data.job.status != JobStatusOK && data.job.status != JobStatusError) {
                    // 任务状态非成功，查询任务
                    queryCvJobsById(data.job.id, true, (success, resp) => {
                        if (success && resp) {
                            let nData = data
                            nData.job = resp.data
                            setData(nData)
                            if (resp.data.status == JobStatusOK || resp.data.status == JobStatusError) {
                                queryResultData()
                            } else {
                                checkJob()
                            }
                        }
                    })
                } else {
                    queryResultData()
                }
            }, 10 * 1000)
        }

        let checkFile = () => {
            if (data.file == undefined) {
                checkJob()
            } else {
                let file = data.file

                const ctx = {
                    maxConcurrency: 5,
                    totalSize: data.file.size,
                    chunkSize: 1024 * 1024 * 4,
                    uploadUrl: UploaderTransportUrl,
                    mergeUrl: UploaderMergeUrl,
                    touchUrl: UploaderTouchUrl,
                    testChunks: false,
                    ext: '',
                }
                const up = uploader(ctx, file)
                up.on('progress', e => {
                    console.log('[UPLOAD] progess', e)
                })
                up.on('success', e => {
                    console.log('[UPLOAD] success', e)
                    if (e.url) {
                        let tosKey = e.url as string
                        if (tosKey.startsWith("https://aiuni.cdn.avar.cn/")) {
                            tosKey = tosKey.slice("https://aiuni.cdn.avar.cn/".length)
                        }
                        console.log("got tos key", tosKey)
                        // 发起角色生成任务
                        addCvTemplates({
                            name: file.name,
                            org_video: tosKey,
                            category: "",
                            tags: ""
                        }, (success, resp) => {
                            if (success && resp) {
                                console.log("add template api success", resp)
                                queryCvJobsById(resp.data.job_id, false, (success, resp) => {
                                    if (success && resp) {
                                        let nData = data
                                        nData.file = undefined
                                        nData.fileUuid = undefined
                                        nData.job = resp.data
                                        setData(nData)
                                        setTimeout(() => {
                                            checkJob()
                                        }, 1000)
                                    }
                                })
                            }
                        })
                    }
                })
                up.on('complete', e => {
                    console.log('[UPLOAD] complete', e)
                })
                up.on('fail', e => {
                    console.log('[UPLOAD] fail', e)
                    // TODO: 上传失败
                })
                up.run()
            }
        }

        checkFile()
    }, []);

    return PagingCard(wp, width, fontSize, marginRight, tosLink(data.cover) + "!h400",
        isMouseEntered ? tosLink(data.display_video) : "",
        "@ " + getCardHint(data.job, "In processing", data.name),
        selected, "template", () => {
            onClick(data)
        }, data.job, data.file, data.fileUuid, undefined, () => {
            setIsMouseEntered(true)
        })
}

interface TcMixVideoCardProps {
    wp: WindowParam
    width: string
    fontSize: string
    marginRight: string,
    tcCvMixVideosData: TcCvMixVideosData,
    selected: boolean,
    onClick: (d: TcCvMixVideosData) => void
}

export const TcMixVideoCard: FC<TcMixVideoCardProps> = ({
                                                            wp,
                                                            width,
                                                            fontSize,
                                                            marginRight,
                                                            tcCvMixVideosData,
                                                            selected,
                                                            onClick
                                                        }: TcMixVideoCardProps) => {
    const [data, setData] = useState<TcCvMixVideosData>(tcCvMixVideosData);
    const [isMouseEntered, setIsMouseEntered] = useState<boolean>(false);

    useEffect(() => {
        let checkJob = () => {
            if (data.job.id == "" || data.job.id == "0") {
                return
            }
            setTimeout(() => {
                let queryResultData = () => {
                    queryCvMixVideosByJobId(data.job.id, (success, resp) => {
                        if (success && resp && resp.data.id != "" && resp.data.id != "0") {
                            resp.data.job.id = ""
                            setData(resp.data)
                        } else {
                            checkJob()
                        }
                    })
                }

                if (data.job.status != JobStatusOK && data.job.status != JobStatusError) {
                    // 任务状态非成功，查询任务
                    queryCvJobsById(data.job.id, true, (success, resp) => {
                        if (success && resp) {
                            let nData = data
                            nData.job = resp.data
                            setData(nData)
                            if (resp.data.status == JobStatusOK || resp.data.status == JobStatusError) {
                                queryResultData()
                            } else {
                                checkJob()
                            }
                        }
                    })
                } else {
                    queryResultData()
                }
            }, 10 * 1000)
        }

        checkJob()
    }, []);

    return PagingCard(wp, width, fontSize, marginRight, tosLink(data.cover) + "!h400",
        isMouseEntered ? tosLink(data.display_video) : "",
        getCardHint(data.job, "Generating", data.character.name),
        selected, "mixVideo", () => {
            onClick(data)
        }, data.job, undefined, undefined, tosLink(data.character.cover + "!ccover"), () => {
            console.log("mouse enter mix video card")
            setIsMouseEntered(true)
        })
}

interface TcCharacterCardProps {
    wp: WindowParam
    width: string
    fontSize: string
    marginRight: string,
    tcCvCharactersData: TcCvCharactersData,
    selected: boolean,
    onClick: (d: TcCvCharactersData) => void
}

export const TcCharacterCard: FC<TcCharacterCardProps> = ({
                                                              wp,
                                                              width,
                                                              fontSize,
                                                              marginRight,
                                                              tcCvCharactersData,
                                                              selected,
                                                              onClick
                                                          }: TcCharacterCardProps) => {
    const [data, setData] = useState<TcCvCharactersData>(tcCvCharactersData);

    useEffect(() => {
        let checkJob = () => {
            if (data.job.id == "" || data.job.id == "0") {
                return
            }
            setTimeout(() => {
                let queryResultData = () => {
                    queryCvCharactersByJobId(data.job.id, (success, resp) => {
                        if (success && resp && resp.data.id != "" && resp.data.id != "0") {
                            resp.data.job.id = ""
                            setData(resp.data)
                        } else {
                            checkJob()
                        }
                    })
                }

                if (data.job.status != JobStatusOK && data.job.status != JobStatusError) {
                    // 任务状态非成功，查询任务
                    queryCvJobsById(data.job.id, true, (success, resp) => {
                        if (success && resp) {
                            let nData = data
                            nData.job = resp.data
                            setData(nData)
                            if (resp.data.status == JobStatusOK || resp.data.status == JobStatusError) {
                                queryResultData()
                            } else {
                                checkJob()
                            }
                        }
                    })
                } else {
                    queryResultData()
                }
            }, 10 * 1000)
        }

        let checkFile = () => {
            if (data.file == undefined) {
                checkJob()
            } else {
                let file = data.file

                const ctx = {
                    maxConcurrency: 5,
                    totalSize: data.file.size,
                    chunkSize: 1024 * 1024 * 4,
                    uploadUrl: UploaderTransportUrl,
                    mergeUrl: UploaderMergeUrl,
                    touchUrl: UploaderTouchUrl,
                    testChunks: false,
                    ext: '',
                }
                const up = uploader(ctx, file)
                up.on('progress', e => {
                    console.log('[UPLOAD] progess', e)
                })
                up.on('success', e => {
                    console.log('[UPLOAD] success', e)
                    if (e.url) {
                        let tosKey = e.url as string
                        if (tosKey.startsWith("https://aiuni.cdn.avar.cn/")) {
                            tosKey = tosKey.slice("https://aiuni.cdn.avar.cn/".length)
                        }
                        console.log("got tos key", tosKey)
                        // 发起角色生成任务
                        addCvCharacters({
                            name: file.name,
                            rig_model: tosKey,
                            category: ""
                        }, (success, resp) => {
                            if (success && resp) {
                                console.log("add character api success", resp)
                                queryCvJobsById(resp.data.job_id, false, (success, resp) => {
                                    if (success && resp) {
                                        let nData = data
                                        nData.file = undefined
                                        nData.fileUuid = undefined
                                        nData.job = resp.data
                                        setData(nData)
                                        setTimeout(() => {
                                            checkJob()
                                        }, 1000)
                                    }
                                })
                            }
                        })
                    }
                })
                up.on('complete', e => {
                    console.log('[UPLOAD] complete', e)
                })
                up.on('fail', e => {
                    console.log('[UPLOAD] fail', e)
                    // TODO: 上传失败
                })
                up.run()
            }
        }

        checkFile()
    }, []);

    return PagingCard(wp, width, fontSize, marginRight, tosLink(data.cover) + "!h400", "",
        "@ " + getCardHint(data.job, "In processing", data.name),
        selected, "character", () => {
            onClick(data)
        }, data.job, data.file, data.fileUuid)
}

function getCardHint(job: TcCvJobsData | undefined, waitingHint: string, name: string): string {
    let isMixVideo = waitingHint == "Generating"
    let prefix = isMixVideo ? "@ " : ""

    if (job != undefined && job.id != "" && job.id != "0") {
        if (job.status == JobStatusError) {
            return prefix + "Failed"
        } else {
            return prefix + waitingHint
        }
    }
    return name
}