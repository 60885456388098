import {WindowParam, WindowParamContext} from "../../../../library/context/WindowContext";
import * as React from "react";
import {Component, FC, useEffect, useState} from "react";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr} from "../../../../library/basic/compose";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {ColorAttr} from "../../../../library/basic/color";
import {FontAttr} from "../../../../library/basic/font";
import "./TcCvHomePanel.css"
import {queryTcCvMixVideosByPage, TcCvMixVideosData, TcCvMixVideosPaginationResp} from "../../data/TcCvMixVideos";
import {queryTcCvCharactersByPage, TcCvCharactersData} from "../../data/TcCvCharacters";
import {TcCvTemplatesData} from "../../data/TcCvTemplates";
import userDefaultAvatar from "../../../../assets/user/nav/user_default_avatar.svg";
import {smallPadding} from "../../display/layout";
import {splitArrayIntoGroups} from "../../../../library/util/arrayUtil";
import {TcMixVideoCard} from "../PagingCard";
import {LoadingOutlined} from "@ant-design/icons";
import {tosLink} from "../../../../library/util/tosUtil";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";


interface TcCvUserHomePanelProps {
    wp: WindowParam
    width: string
    height: string
    showMixVideoDetailCard: (d: TcCvMixVideosData) => void
    showCharacterDetailCard: (d: TcCvCharactersData) => void
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
}

export const TcCvUserHomePanel: FC<TcCvUserHomePanelProps> = ({
                                                                  wp,
                                                                  width,
                                                                  height,
                                                                  showMixVideoDetailCard,
                                                                  showCharacterDetailCard,
                                                                  showTemplateDetailCard
                                                              }: TcCvUserHomePanelProps) => {
    let widthNum = SizeAttr.getPxNumber(width);
    let innerColWidthNum = widthNum - 32 * 2

    return (
        <ColumnUnit sizeAttr={new SizeAttr(wp, width, height)}>
            <TcCvUserHomeMixVideosPanel
                width={innerColWidthNum + "px"}
                height={height}
                showMixVideoDetailCard={showMixVideoDetailCard}
                showCharacterDetailCard={showCharacterDetailCard} />
        </ColumnUnit>
    )
}

interface TcCvUserHomeCharacterLineProps {
    wp: WindowParam
    count: number
    width: string
    height: string
    gap: string
    showCharacterDetailCard: (d: TcCvCharactersData) => void
}

const TcCvUserHomeCharacterLine: FC<TcCvUserHomeCharacterLineProps> = ({
                                                                           wp,
                                                                           count,
                                                                           width,
                                                                           height,
                                                                           gap,
                                                                           showCharacterDetailCard
                                                                       }: TcCvUserHomeCharacterLineProps) => {
    const [characters, setCharacters] = useState<TcCvCharactersData[]>([]);

    useEffect(() => {
        queryTcCvCharactersByPage({category: "", keyword: "", page_num: 1, page_size: count}, (success, resp) => {
            if (success && resp) {
                setCharacters(resp.data.list)
            }
        })
    }, []);

    return (
        <RowUnit
            classname={"home-panel-character-line"}
            customStyleAttr={{
                "minHeight": height,
                "overflowX": "scroll"
            }}
            sizeAttr={new SizeAttr(wp, width, height)}>
            {
                characters.map((c, idx) => {
                    return (
                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                showCharacterDetailCard(c)
                            }}
                            key={"TcCvHomePanel-TcCvUserHomeCharacterLine-Character-" + c.id + "-" + idx}
                            sizeAttr={new SizeAttr(wp, height, height)}
                            customStyleAttr={{
                                "marginRight": idx == characters.length - 1 ? "0px" : gap,
                                "borderRadius": "16px",
                                "background": "#141415"
                            }}
                        >
                            <img
                                style={{
                                    width: height,
                                    height: height,
                                    borderRadius: "16px"
                                }}
                                alt={""}
                                src={tosLink(c.cover + "!ccover")}/>
                        </BoxUnit>
                    )
                })
            }
        </RowUnit>
    )
}

type TcCvUserHomeMixVideosPanelProps = {
    width: string
    height: string
    showMixVideoDetailCard: (d: TcCvMixVideosData) => void
    showCharacterDetailCard: (d: TcCvCharactersData) => void
}

type TcCvUserHomeMixVideosPanelState = {
    scrollPosition: number,
    scrollHeight: number,
    isLoading: boolean,
    dataFetched: boolean,
    pageNum: number,
    total: number,
    dataList: TcCvMixVideosData[],
    selectedId: string
    lineCount: number
    cardWidthNum: number
}

class TcCvUserHomeMixVideosPanel extends Component<TcCvUserHomeMixVideosPanelProps> {
    static contextType = WindowParamContext;

    wp: WindowParam = new WindowParam()
    private readonly scrollContainerRef: React.RefObject<HTMLElement>

    constructor(props: any) {
        super(props);
        this.scrollContainerRef = React.createRef<HTMLElement>();
    }

    state: TcCvUserHomeMixVideosPanelState = {
        scrollPosition: 0,
        scrollHeight: 0,
        isLoading: false,
        dataFetched: false,
        pageNum: 0,
        total: 0,
        dataList: [],
        selectedId: "",
        lineCount: 6,
        cardWidthNum: 0
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            if (callback !== undefined) {
                callback()
            }
        })
    }

    componentDidMount() {
        let widthNum = SizeAttr.getPxNumber(this.props.width)
        let cardGap = 16
        let lineCount = 5
        let cardWidthNum = (widthNum - cardGap * (lineCount - 1)) / lineCount

        this.updateAndSaveState({lineCount: lineCount, cardWidthNum: cardWidthNum}, () => {
            this.queryNextPage()
        })
    }

    queryNextPage() {
        if (this.state.isLoading) return
        if (this.state.dataFetched && this.state.dataList.length >= this.state.total) {
            return;
        }

        let that = this
        let respCallback = function (success: boolean, resp: TcCvMixVideosPaginationResp | undefined) {
            if (success && resp != undefined) {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": that.state.pageNum + 1,
                    "total": resp.data.total,
                    "dataList": that.state.pageNum + 1 == 1 ? resp.data.list : that.state.dataList.concat(resp.data.list),
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            } else {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": 0,
                    "total": 0,
                    "dataList": [],
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            }
        }

        this.setState(prevState => (Object.assign(prevState,
            {
                "isLoading": true
            }) as TcCvUserHomeMixVideosPanelState), () => {
            queryTcCvMixVideosByPage({
                keyword: "",
                page_num: this.state.pageNum + 1,
                page_size: this.state.lineCount * 4
            }, (success, resp) => {
                respCallback(success, resp)
            })
        })
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;

                    let textMargin = 16
                    let characterLineMarginBottom = 32

                    let cardGap = 16

                    return (
                        <ColumnUnit
                            classname={"home-panel-character-line"}
                            onScroll={(e) => {
                                let target = e.target as HTMLDivElement
                                if (target) {
                                    let activeScrollTop = target.scrollTop
                                    this.setState(prevState => (Object.assign(prevState,
                                        {
                                            "scrollPosition": activeScrollTop,
                                            "scrollHeight": target.scrollHeight
                                        })))
                                    if (activeScrollTop == 0) {
                                        this.updateAndSaveState({
                                            "dataFetched": false,
                                            "pageNum": 0,
                                            "pageSize": this.state.lineCount * 2,
                                            "total": 0
                                        }, () => {
                                            this.queryNextPage()
                                        })
                                    } else if (activeScrollTop >= target.scrollHeight - wp.currHeight) {
                                        this.queryNextPage()
                                    }
                                }
                            }}
                            unitRef={this.scrollContainerRef}
                            customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {"overflowY": "auto"})}
                            sizeAttr={new SizeAttr(wp, this.props.width, this.props.height)}>

                            <SpanText
                                colorAttr={new ColorAttr(wp, "#E2E2EA")}
                                customStyleAttr={{
                                    "justifyContent": "",
                                    "marginTop": textMargin + "px",
                                    "marginBottom": textMargin + "px"
                                }}
                                fontAttr={new FontAttr(wp, "18px", "600")}
                            >
                                {"Featured Character"}
                            </SpanText>

                            <TcCvUserHomeCharacterLine
                                wp={wp} count={20} width={this.props.width}
                                height={"140px"} gap={"16px"}
                                showCharacterDetailCard={this.props.showCharacterDetailCard}/>

                            <SpanText
                                colorAttr={new ColorAttr(wp, "#E2E2EA")}
                                customStyleAttr={{
                                    "justifyContent": "",
                                    "marginTop": characterLineMarginBottom + "px",
                                    "marginBottom": smallPadding
                                }}
                                fontAttr={new FontAttr(wp, "18px", "600")}
                            >
                                {"Featured Animation"}
                            </SpanText>

                            {
                                this.state.dataList.length >= 0 &&
                                splitArrayIntoGroups(this.state.dataList, this.state.lineCount).map((rowDataList, idx) => {
                                    return (
                                        <RowUnit
                                            key={"TcCvHomePanel-TcCvUserHomeMixVideosPanel-" + idx}
                                            customStyleAttr={{
                                                "marginTop": smallPadding,
                                                "marginBottom": smallPadding,
                                                "marginRight": "auto"
                                            }}
                                        >
                                            {
                                                rowDataList.map((rd, rIdx) => {
                                                    return <TcMixVideoCard
                                                        wp={wp}
                                                        width={this.state.cardWidthNum + "px"}
                                                        fontSize={"18px"}
                                                        marginRight={rIdx == this.state.lineCount - 1 ? "" : cardGap + "px"}
                                                        tcCvMixVideosData={rd}
                                                        selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                        onClick={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                            this.props.showMixVideoDetailCard(d)
                                                        }}
                                                    />
                                                })
                                            }
                                        </RowUnit>
                                    )
                                })
                            }

                            <RowUnit sizeAttr={new SizeAttr(wp, this.props.width, "64px")}>
                                {
                                    this.state.isLoading && this.state.pageNum > 0 &&
                                    <LoadingOutlined
                                        size={48}
                                        style={BoxAttr.getChildrenPositionObj()}/>
                                }
                            </RowUnit>
                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}
