const keyHint = "keyHint";
const keyPrefix = "uKey-";

export function checkKeyInSession(key: string | undefined) : boolean {
    if (!key) return false;
    return sessionStorage.getItem(keyPrefix + key) === keyHint;
}

export function storeKeyInSession(key: string | undefined) {
    if (!key) return;
    sessionStorage.setItem(keyPrefix + key, keyHint);
}

export function removeKeyInSession(key: string | undefined) {
    if (!key) return;
    sessionStorage.removeItem(keyPrefix + key);
}

export function copyToClipboard(txtStr: string){
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', txtStr);
    input.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        console.log('复制成功');
    }
    document.body.removeChild(input);
}