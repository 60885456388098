import {TC_AIUNI_CV_BACKEND_URL} from "../../../library/util/apiUtil";
import {getToken} from "../util/account";
import {emptyJobData, TcCvJobsData} from "./TcCvJob";
import {encodeId} from "../util/id";
import {v4 as uuidv4} from "uuid";
import {emptyUserDb, UserDb} from "./TcCvUser";

export type TcCvTemplatesData = {
    id: string
    name: string
    cover: string,
    display_video: string
    inpaint_video: string
    org_video: string
    category: string
    bvh: string
    tags: string
    job: TcCvJobsData
    user: UserDb
    file?: File
    fileUuid?: string
}

export function getInitTemplateDataFromFile(file: File): TcCvTemplatesData {
    return {
        id: "",
        name: "",
        cover: "",
        display_video: "",
        inpaint_video: "",
        org_video: "",
        category: "",
        bvh: "",
        tags: "",
        job: emptyJobData(),
        user: emptyUserDb(),
        file: file,
        fileUuid: uuidv4()
    }
}

export type TcCvTemplatesPaginationData = {
    total: number
    list: TcCvTemplatesData[]
}

export type TcCvTemplatesPaginationResp = {
    code: number
    msg: string
    data: TcCvTemplatesPaginationData
}

export type TcCvTemplatesListResp = {
    code: number
    msg: string
    data: TcCvTemplatesData[]
}

export type QueryTcCvTemplatesByPageReq = {
    category: string
    keyword: string
    page_num: number
    page_size: number
}

export function queryTcCvTemplatesByPage(req: QueryTcCvTemplatesByPageReq, callback: (success: boolean, resp?: TcCvTemplatesPaginationResp) => void) {
    let innerReq: QueryTcCvTemplatesByPageReq = {
        category: req.category == "ALL" ? "" : req.category,
        keyword: req.keyword,
        page_num: req.page_num,
        page_size: req.page_size
    }

    fetch(TC_AIUNI_CV_BACKEND_URL + "/recommendCvTemplatesByPage" + "?page_num=" + innerReq.page_num + "&page_size="
        + innerReq.page_size + "&" + "category=" + innerReq.category + "&" + "keyword=" + innerReq.keyword)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvTemplatesPaginationResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type QueryUserCvTemplatesByPageReq = {
    page_num: number
    page_size: number
}

export function queryUserCvTemplatesByPage(req: QueryUserCvTemplatesByPageReq, callback: (success: boolean, resp?: TcCvTemplatesPaginationResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryUserCvTemplatesWithJobs?limit=100", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvTemplatesListResp;
                callback(resp.code === 200, {
                    code: 200,
                    msg: resp.msg,
                    data: {
                        total: resp.data.length,
                        list: resp.data
                    }
                });
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type TcCvTemplatesResp = {
    code: number
    msg: string
    data: TcCvTemplatesData
}

export function queryCvTemplatesByJobId(jobId: string, callback: (success: boolean, resp?: TcCvTemplatesResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryCvTemplatesByJobId?job_id=" + encodeId(jobId), options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvTemplatesResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type AddCvTemplatesReq = {
    name: string
    org_video: string
    category: string
    tags: string
}

export type AddCvTemplateResp = {
    error: number
    message: string
    msg: string
    data: {
        job_id: string
    }
}

export function addCvTemplates(req: AddCvTemplatesReq, callback: (success: boolean, resp?: AddCvTemplateResp) => void) {
    let innerReq = {
        video: req.org_video,
        name: req.name,
        category: req.category
    }

    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        },
        body: JSON.stringify(innerReq),
    };

    fetch("https://api.aiuni.ai/openapi/cv/job/v2m", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as AddCvTemplateResp;
                callback(resp.error === 0 && resp.msg == "ok", resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}