import {TC_AIUNI_CV_BACKEND_URL} from "../../../library/util/apiUtil";
import {getToken} from "../util/account";
import {TcCvTemplatesData, TcCvTemplatesListResp} from "./TcCvTemplates";
import {TcCvCharactersData} from "./TcCvCharacters";
import {UserDb, UserInfo} from "./TcCvUser";
import {encodeId} from "../util/id";
import {TcCvJobsData} from "./TcCvJob";

export type TcCvMixVideosData = {
    id: string
    name: string
    cover: string
    template_id: string
    character_id: string
    mix_video: string
    display_video: string
    template: TcCvTemplatesData
    character: TcCvCharactersData
    user: UserDb
    job: TcCvJobsData
}

export type TcCvMixVideosPaginationData = {
    total: number
    list: TcCvMixVideosData[]
}

export type TcCvMixVideosPaginationResp = {
    code: number
    msg: string
    data: TcCvMixVideosPaginationData
}

export type TcCvMixVideosListResp = {
    code: number
    msg: string
    data: TcCvMixVideosData[]
}

export type QueryTcCvMixVideosByPageReq = {
    keyword: string
    page_num: number
    page_size: number
}

export function queryTcCvMixVideosByPage(req: QueryTcCvMixVideosByPageReq, callback: (success: boolean, resp?: TcCvMixVideosPaginationResp) => void) {
    fetch(TC_AIUNI_CV_BACKEND_URL + "/recommendCvMixVideosByPage" + "?page_num=" + req.page_num + "&page_size="
        + req.page_size + "&keyword=" + req.keyword)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvMixVideosPaginationResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type QueryUserCvMixVideosByPageReq = {
    page_num: number
    page_size: number
}

export function queryUserCvMixVideosByPage(req: QueryUserCvMixVideosByPageReq, callback: (success: boolean, resp?: TcCvMixVideosPaginationResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryUserCvMixVideosWithJobs?limit=100", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvMixVideosListResp;
                callback(resp.code === 200, {
                    code: 200,
                    msg: resp.msg,
                    data: {
                        total: resp.data.length,
                        list: resp.data
                    }
                });
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type AddCvMixVideosReq = {
    template_id: string
    character_id: string
}

export type AddCvMixVideosResp = {
    error: number
    message: string
    msg: string
    data: any
}

export function addCvMixVideos(req: AddCvMixVideosReq, callback: (success: boolean, resp?: AddCvMixVideosResp) => void) {
    let innerReq: AddCvMixVideosReq = {
        template_id: encodeId(req.template_id),
        character_id: encodeId(req.character_id)
    }

    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        },
        body: JSON.stringify(innerReq),
    };

    fetch("https://api.aiuni.ai/openapi/cv/job/mix_video", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as AddCvMixVideosResp;
                callback(resp.error === 0 && resp.msg == "ok", resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type TcCvMixVideosResp = {
    code: number
    msg: string
    data: TcCvMixVideosData
}

export function queryCvMixVideosByJobId(jobId: string, callback: (success: boolean, resp?: TcCvMixVideosResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryCvMixVideosByJobId?job_id=" + encodeId(jobId), options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvMixVideosResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type QueryCvMixVideosByCharacterByPageReq = {
    character_id: string
    page_num: number
    page_size: number
}

export function queryCvMixVideosByCharacterByPage(req: QueryCvMixVideosByCharacterByPageReq, callback: (success: boolean, resp?: TcCvMixVideosPaginationResp) => void) {
    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryCvMixVideosByCharacterByPage" + "?page_num=" + req.page_num + "&page_size="
        + req.page_size + "&character_id=" + encodeId(req.character_id))
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvMixVideosPaginationResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type QueryCvMixVideosByTemplateByPageReq = {
    template_id: string
    page_num: number
    page_size: number
}

export function queryCvMixVideosByTemplateByPage(req: QueryCvMixVideosByTemplateByPageReq, callback: (success: boolean, resp?: TcCvMixVideosPaginationResp) => void) {
    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryCvMixVideosByTemplateByPage" + "?page_num=" + req.page_num + "&page_size="
        + req.page_size + "&template_id=" + encodeId(req.template_id))
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvMixVideosPaginationResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}